import React, { useRef, useEffect } from "react";
import styles from "../skiSchool.module.scss";
import SwiperSlider from "../../General/swiper/swiper";
import { Container } from "@material-ui/core";
import useLocale from "../../../lang/useLocale";
import AOS from "aos";
import "aos/dist/aos.css";
// import { Grid} from '@material-ui/core'

export default function LessonTutorial() {
	const { getLocale } = useLocale();
	const mySwiper = useRef();
	const settings = {
		loop: false,
		spaceBetween: 20,
		breakpoints: {
			640: {
				slidesPerView: 1,
			},
			768: {
				slidesPerView: 1,
			},
			1025: {
				slidesPerView: 2,
			},
		},
		arrows: true,
	};
	useEffect(() => {
		AOS.init({
			duration: 1000,
			once: true
		});
	}, []);
	return (
		<div className={styles.lessonTutorial} id="swiperTutorial">
			<Container maxWidth="lg">
				<h4 className={styles.title}>{getLocale("LESSON TUTORIALS")}</h4>
				<div data-aos="fade-up">
					<SwiperSlider settings={settings} mySwiper={mySwiper}>
						<div className={`${styles.packageSlide} swiper-slide`}>
							<iframe
								title="video"
								width="100%"
								height="280"
								src="https://www.youtube.com/embed/EEFDQ3Dg5Qs"
								frameBorder="0"
								allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
							></iframe>
						</div>
						<div className={`${styles.packageSlide} swiper-slide`}>
							<iframe
								title="video"
								width="100%"
								height="280"
								src="https://www.youtube.com/embed/cqKND0mVslg"
								frameBorder="0"
								allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
							></iframe>
						</div>
					</SwiperSlider>
				</div>
			</Container>
		</div>
	);
}

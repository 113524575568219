import React, { useRef, useEffect } from "react";
import styles from "./meetTrainers.module.scss";
import Slider from "../../General/swiper/swiper";
import * as trainers from "./trainersImgs";
import { Container } from "@material-ui/core";
import useLocale from "../../../lang/useLocale";
import { Grid } from "@material-ui/core";
import AOS from "aos";
import "aos/dist/aos.css";

export default function MeetSchoolTrainers() {
	const { getLocale } = useLocale();
	const mySwiper = useRef();
	const settings = {
		arrows: true,
		bullets: false,
		loop: false,
		spaceBetween: 15,
		autoplay: {
			delay: 10000,
		},
		autoplayDisableOnInteraction: true,
		breakpoints: {
			640: {
				slidesPerView: 1,
			},
			768: {
				slidesPerView: 2,
			},
			1025: {
				slidesPerView: 4,
			},
		},
	};
	useEffect(() => {
		AOS.init({
			duration: 1000,
			once: true
		});
	}, []);
	return (
		<div id="swiperTrainers" className={`${styles.ticketsPenguins} ${styles.trainers}`}>
			<Container maxWidth="lg">
				<div className={styles.meet}>
					<h4>{getLocale("MEET THE TRAINERS")}</h4>
				</div>
				<div data-aos="fade-up">
					<div id="swiperPenguinTeam">
						<Slider settings={settings} mySwiper={mySwiper}>
							<div className={`swiper-slide ${styles.penguinSlide}`}>
								<img src={trainers.MahmoudAbdou} alt="trainer" />
								<h5>{getLocale("Mahmoud Abdou")}</h5>
								<Grid container justify="space-between">
									<Grid item xs={12} className={styles.sliderItem}>
										<span>{getLocale("Ski School manager")}</span>
									</Grid>
								</Grid>
							</div>
							{/* <div className={`swiper-slide ${styles.penguinSlide}`}>
							<img src={trainers.ShaimaaAdel} alt="trainer" />
							<h5>{getLocale("Shaimaa Adel")}</h5>
							<Grid container justify="space-between">
								<Grid item xs={12} className={styles.sliderItem}>
									<span>{getLocale("Senior Ski Patroller - Ski Instructor")}</span>
								</Grid>
							</Grid>
						</div> */}
							<div className={`swiper-slide ${styles.penguinSlide}`}>
								<img src={trainers.MostafaElZoghby} alt="trainer" />
								<h5>{getLocale("Mostafa El Zoghby")}</h5>
								<Grid container justify="space-between">
									<Grid item xs={12} className={styles.sliderItem}>
										<span>{getLocale("Senior Ski Instructor - Ski Patroller - Snowboard Instructor")}</span>
									</Grid>
								</Grid>
							</div>
							<div className={`swiper-slide ${styles.penguinSlide}`}>
								<img src={trainers.SamahSabry} alt="مدربين حديقة الثلج مصر" />
								<h5>{getLocale("Samah Sabry")}</h5>
								<Grid container justify="space-between">
									<Grid item xs={12} className={styles.sliderItem}>
										<span>{getLocale("Senior Ski Patroller - Ski Instructor - Snowboard Instructor")}</span>
									</Grid>
								</Grid>
							</div>
							<div className={`swiper-slide ${styles.penguinSlide}`}>
								<img src={trainers.AhmedMohsen} alt="trainer" />
								<h5>{getLocale("Ahmed Mohsen")}</h5>
								<Grid container justify="space-between">
									<Grid item xs={12} className={styles.sliderItem}>
										<span>{getLocale("Ski Instructor - Ski Patroller")}</span>
									</Grid>
								</Grid>
							</div>
							{/* <div className={`swiper-slide ${styles.penguinSlide}`}>
							<img src={trainers.abdelrahman} alt="trainer" />
							<h5>{getLocale("Abdel Rahman")}</h5>
							<Grid container justify="space-between">
								<Grid item xs={12} className={styles.sliderItem}>
									<span>{getLocale("Ski School Administrator")}</span>
								</Grid>
							</Grid>
						</div> */}
							<div className={`swiper-slide ${styles.penguinSlide}`}>
								<img src={trainers.AhmedAkram} alt="trainer" />
								<h5>{getLocale("Ahmed Akram")}</h5>
								<Grid container justify="space-between">
									<Grid item xs={12} className={styles.sliderItem}>
										<span>{getLocale("Ski Instructor")}</span>
									</Grid>
								</Grid>
							</div>

							{/* <div className={`swiper-slide ${styles.penguinSlide}`}>
							<img src={trainers.AhmedRabea} alt="trainer" />
							<h5>{getLocale("Ahmed Rabea")}</h5>
							<Grid container justify="space-between">
								<Grid item xs={12} className={styles.sliderItem}>
									<span>{getLocale("Ski Instructor")}</span>
								</Grid>
							</Grid>
						</div> */}
							<div className={`swiper-slide ${styles.penguinSlide}`}>
								<img src={trainers.HeshamFathy} alt="trainer" />
								<h5>{getLocale("Hesham Fathy")}</h5>
								<Grid container justify="space-between">
									<Grid item xs={12} className={styles.sliderItem}>
										<span>{getLocale("Ski Instructor - Snowboard Instrcutor")}</span>
									</Grid>
								</Grid>
							</div>
							<div className={`swiper-slide ${styles.penguinSlide}`}>
								<img src={trainers.IslaMagdy} alt="trainer" />
								<h5>{getLocale("Islam Magdy")}</h5>
								<Grid container justify="space-between">
									<Grid item xs={12} className={styles.sliderItem}>
										<span>{getLocale("Ski Instructor")}</span>
									</Grid>
								</Grid>
							</div>
							<div className={`swiper-slide ${styles.penguinSlide}`}>
								<img src={trainers.KareemLoutfy} alt="trainer" />
								<h5>{getLocale("Kareem Loutfy")}</h5>
								<Grid container justify="space-between">
									<Grid item xs={12} className={styles.sliderItem}>
										<span>{getLocale("Ski Instructor - Snowboard Instrcutor")}</span>
									</Grid>
								</Grid>
							</div>
							<div className={`swiper-slide ${styles.penguinSlide}`}>
								<img src={trainers.MostafaEssam} alt="trainer" />
								<h5>{getLocale("Mostafa Essam")}</h5>
								<Grid container justify="space-between">
									<Grid item xs={12} className={styles.sliderItem}>
										<span>{getLocale("Ski Instructor")}</span>
									</Grid>
								</Grid>
							</div>
						</Slider>
					</div>
				</div>
			</Container>
		</div>
	);
}

import React from "react";
import styles from "./groupPackages.module.scss";
import useLocale from "../../lang/useLocale";
import corporateBenefits from "../../img/corporateBenefitsPanal.jpg";
import Banner from "../General/headerPanel/headerPanel";
// import OurAttractions from "../General/Our-Attractions/Our-Attractions"
import SocialSection from '../General/social-section/social-section'
import {Helmet} from "react-helmet";

import TabsForGroupPackages from "./subComponents/tabsForGroupPackages/tabsForGroupPackages";

export default function GroupPackages() {
    const { getLocale } = useLocale();
    return (
        <div className={styles.GroupPackages}>
             <Helmet>
    <meta
      name="description"
      content={getLocale("Visit ski Egypt to know packages and enjoy booking our birthday group packages, school trip, or corporate group Package.")}
    />
      <title>{getLocale("Visit SKi to Know Group Packages | SKI Egypt")}</title>
            </Helmet>
            <Banner
                panal={corporateBenefits}
                containerCard
                title={getLocale("GROUP PACKAGES")}
                description={getLocale("groupPackagesContent")}
            />
            <TabsForGroupPackages />
            {/* <OurAttractions /> */}
            <SocialSection/>

        </div>
    );
}

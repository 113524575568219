import React from "react";
// import panalOffers from "../../img/Banner-Website.gif";
// import panalOffersMob from "../../img/Banner-Mobile.gif";
import panalOffers from "../../img/extreme-adrenaline.gif";
import panalOffersMob from "../../img/extreme-adrenaline-mob.gif";
// import panalOffersMob from "../../img/Banner-Website.png";
import Packages from "./subOffers/Packages/packages";
import HeaderPanel from "../General/headerPanel/headerPanel";
import styles from "./offers.module.scss";
import SocialSection from "../General/social-section/social-section";
import useLocale from "../../lang/useLocale";
import { Container, Grid } from "@material-ui/core";
// import happyHours from "../../img/happy.jpg";
// import offerTwo from "../../img/offer-two.png";
// import family1 from "../../img/snowgo.jpg";
// import SnowPremium from "../../img/snow-premium-image.png";
// import SkiGear from "../../img/ski-gear.png";
// import snowClassicOfferEn from '../../img/snow-classic-offer-en.png';
// import snowClassicOfferAr from '../../img/snow-classic-offer-ar.png';
// import snowPlusOfferEn from '../../img/snow-plus-offer-en.png';
// import snowPlusOfferAr from '../../img/snow-plus-offer-ar.png';
import { Helmet } from "react-helmet";

// import family2 from "../../img/family.jpg";
// import leftArrow from "../../img/left-arrow.svg";
// import close from "../../img/close.svg";
// import styles2 from "../offers/subOffers/GridPackages/GridPackages.module.scss";
// import newOffer from "../../img/ski48.jpg";
import { useSelector } from "react-redux";
// import discoverOfferEn from '../../img/discovery-lesson-offer-en.png';
// import discoverOfferAr from '../../img/discovery-lesson-offer-ar.png';
// import snowPlus from '../../img/snow-plus-offer.png';
function Offers() {
  // const lang = useSelector((state) => state.lang);
  const { getLocale } = useLocale();
  // const [open1, setOpen1] = useState(false);
  // const [open2, setOpen2] = useState(false);
  // const [open3, setOpen3] = useState(false);
  // const [open4, setOpen4] = useState(false);

  // const handleOpen1 = () => {
  // 	setOpen1(true);
  // };
  // const handleOpen2 = () => {
  // 	setOpen2(true);
  // };
  // const handleOpen3 = () => {
  // 	setOpen3(true);
  // };
  // const handleOpen4 = () => {
  // 	setOpen4(true);
  // };

  // const handleClose1 = () => {
  // 	setOpen1(false);
  // };
  // const handleClose2 = () => {
  // 	setOpen2(false);
  // };
  // const handleClose3 = () => {
  // 	setOpen3(false);
  // };
  // const handleClose4 = () => {
  // 	setOpen4(false);
  // };
  // Screen size from redux
  const size = useSelector((state) => state.size);

  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content={getLocale(
            "Book Ski Egypt tickets & packages online and get exclusive offers. Visit ski Egypt now to know more about the offers."
          )}
        />
        <title>
          {getLocale("Ski Egypt Offers | Book Now! to Enjoy Offers| SKI Egypt")}
        </title>
      </Helmet>
      <div>
        {/* <Modal open={open1} onClose={handleClose1}>
					<div className={`${styles2.containerGridAll} ${styles2.modal}`}>
						<img className={styles2.close} onClick={handleClose1} src={close} alt="img" />
						<Grid
							container
							item
							xs={12}
							md={12}
							className={`${styles2.containerData}`}
							justify="flex-start"
							alignItems="flex-start"
						>
							<div className={styles2.header2}>
								<h2>{getLocale("Snow Plus Offer")}</h2>
								<p>{getLocale("snow plus offer Content")}</p>
							</div> */}
        {/* <Grid item xs={12} className={`${styles2.Header}`}>
								<p>{getLocale("Get on the Purchase of Snow Classic")}</p>
							</Grid>
							<Grid item xs={12} className={`${styles2.description}`}>
								<ul>
									<li>{getLocale("Free hot chocolate")}</li>
									<li>{getLocale("Free locker")}</li>
								</ul>
							</Grid> */}
        {/* <Grid container item xs={12} className={`${styles2.PackagePerPerson}`}>
								<Grid item className={`${styles2.Header}`}>
									<p>{getLocale("Valid till 31/3/2021")}</p>
								</Grid>
							</Grid> */}
        {/* <Grid container item xs={12} className={`${styles2.description}`}>
								<Grid item xs={6} md={4}>
								<a
									href={`https://booking.skiegy.com/${lang}/snow-park/booking/snow-plus`}
									target="_self"
									rel="noopener noreferrer"
								>
																			{getLocale("BOOK NOW")}
									</a>
								</Grid>
							</Grid>
						</Grid>
					</div>
				 </Modal> */}
        {/* <Modal open={open2} onClose={handleClose2}>
					<div className={`${styles2.containerGridAll} ${styles2.modal}`}>
						<img className={styles2.close} onClick={handleClose2} src={close} alt="img" />
						<Grid
							container
							item
							xs={12}
							md={12}
							className={`${styles2.containerData}`}
							justify="flex-start"
							alignItems="flex-start"
						> */}
        {/* <div className={styles2.header2}>
								<h2>{getLocale("Snow Classic Offer")}</h2>
								<p>{getLocale("Snow Classic Offer Content")}</p>
							</div> */}
        {/* <Grid item xs={12} className={`${styles2.description}`}>

								<p>{getLocale("A family of 4, 2 adults and 2 youngsters aged between 3-5 years old")}</p>
							<ul>
									<li>{getLocale("Free Locker")}</li>
								<li>{getLocale("Package 1 list 2")}</li>
								<li>{getLocale("Package 1 list 3")}</li>
									<li>{getLocale("Package 1 list 4")}</li>
								</ul>
							</Grid> */}
        {/* <Grid container item xs={12} className={`${styles2.PackagePerPerson}`}> */}
        {/* <Grid item className={`${styles2.Header}`}>
									<p>{getLocale("Valid till 31/3/2021")}</p>
								</Grid> */}
        {/* <Grid item xs={12} className={`${styles2.description}`}> */}
        {/* <Grid container> */}
        {/* <Grid item xs={4} md={3}>
											<p>
												800
												<span> / {getLocale("LE")} </span>
											</p>
										</Grid> */}
        {/* <Grid container item xs={12} alignItems="center"> */}
        {/* <Grid item xs={6} md={6}>
												<a href="/" target="_blank" className="bookDisabled">
													{getLocale("BOOK NOW")}
												</a>
											</Grid> */}
        {/* <Grid item xs={6} md={6}>
												<a href={`${lang === "en" ?  "https://booking.skiegy.com/en/snow-park/booking/snow-classic"  :  "https://booking.skiegy.com/ar/snow-park/booking/snow-classic" }`} rel="noopener noreferrer">
												{getLocale("BOOK NOW")}
												</a>
											</Grid> */}
        {/* </Grid> */}
        {/* </Grid> */}
        {/* </Grid> */}
        {/* </Grid>
						</Grid>
					</div>
				</Modal> */}
        {/* <Modal open={open3} onClose={handleClose3}>
					<div className={`${styles2.containerGridAll} ${styles2.modal}`}>
						<img className={styles2.close} onClick={handleClose3} src={close} alt="img" />
						<Grid
							container
							item
							xs={12}
							md={12}
							className={`${styles2.containerData}`}
							justify="flex-start"
							alignItems="flex-start"
						> */}
        {/* <div className={styles2.header2}>
								<h2>{getLocale("Snow Plus Offer")}</h2>
								<p>{getLocale("Snow Plus Offer Content")}</p>
							</div> */}
        {/* <Grid item xs={12} className={`${styles2.description}`}>
								<p>{getLocale("A family of 4, 2 adults and 2 youngsters aged between 3-5 years old")}</p>
								<ul>
									<li>{getLocale("Free Locker")}</li>
									<li>{getLocale("Package 2 list 2")}</li>
									<li>{getLocale("Package 2 list 3")}</li>
									<li>{getLocale("Package 2 list 4")}</li>
								</ul>
							</Grid> */}
        {/* <Grid container item xs={12} className={`${styles2.PackagePerPerson}`}> */}
        {/* <Grid item className={`${styles2.Header}`}>
									<p>{getLocale("Valid till 31/3/2021")}</p>
								</Grid> */}
        {/* <Grid container item className={`${styles2.description}`} alignItems="center"> */}
        {/* <Grid container alignItems="center"> */}
        {/* <Grid item xs={4} md={3}>
											<p>
												925
												<span> / {getLocale("LE")} </span>
											</p>
										</Grid> */}
        {/* <Grid container item xs={12} alignItems="center"> */}
        {/* <Grid item xs={6} md={6}>
												<a href="/" target="_blank" className="bookDisabled">
													{getLocale("BOOK NOW")}
												</a>
											</Grid>  */}
        {/* <Grid item xs={6} md={6}>
					 <a href={`${lang === "en" ?  "https://booking.skiegy.com/en/snow-park/booking/snow-plus"  :  "https://booking.skiegy.com/ar/snow-park/booking/snow-plus" }`} rel="noopener noreferrer">
												{getLocale("BOOK NOW")}
												</a>
											</Grid> */}
        {/* </Grid> */}
        {/* </Grid> */}
        {/* </Grid> */}
        {/* </Grid>
						</Grid>
					</div>
				</Modal> */}
        {/* <Modal open={open4} onClose={handleClose4}>
					<div className={`${styles2.containerGridAll} ${styles2.modal}`}>
						<img className={styles2.close} onClick={handleClose4} src={close} alt="img" />
						<Grid
							container
							item
							xs={12}
							md={12}
							className={`${styles2.containerData}`}
							justify="flex-start"
							alignItems="flex-start"
						>
							<div className={styles2.header2}>
								<h2>{getLocale("Ski in 48 hours")}</h2> */}
        {/* <p>{getLocale("Ski48exert")}</p> */}
        {/* </div>
							<Grid item xs={12} className={`${styles2.description}`}>
								<p>
									<p>{getLocale("Ski48exert")}</p>
								</p>
								<ul>
									<li>{getLocale("1 hot chocolate during the 2 days")}</li>
									<li>{getLocale("20% discount at Snow Pro")}</li>
									<li>{getLocale("25% discount at Melt")}</li>
									<li>
										{getLocale(
											"20% discount on group return lessons (In case you wish to continue the remaining levels)"
										)}
									</li>
								</ul>
							</Grid> */}
        {/* <Grid container item xs={12} className={`${styles2.PackagePerPerson}`}>
								<Grid item className={`${styles2.Header}`}>
									<p>{getLocale("Total fee")}</p>
								</Grid>
								<Grid container item className={`${styles2.description}`} alignItems="flex-start">
									<Grid container justify="flex-start" alignItems="center">
										<Grid item xs={12} md={12} container justify="flex-start">
											<p style={{ margin: "0 5px" }}>
												650
												<span>
													/ {getLocale("LE")} {getLocale("instead")}
												</span>
											</p>
											<p style={{ textDecoration: "line-through" }}>
												1,100
												<span> / {getLocale("LE")}</span>
											</p>
										</Grid> */}
        {/* <Grid container justify="space-around" item xs={12} alignItems="center">
											<Grid item xs={6} md={6}>
												<a href="/" target="_blank" className="bookDisabled">
													{getLocale("BOOK NOW")}
												</a>
											</Grid>
											<Grid item xs={6} md={6}>
												<a href="tel:16002" rel="noopener noreferrer">
													{getLocale("CALL US")}
												</a>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</div>
				</Modal> */}
      </div>

      <HeaderPanel
        panal={size >= 900 ? panalOffers : panalOffersMob}
        title=""
        description=""
        hideCardTitle
        className="gif"
      />
      <Container className={styles.offerssubZero}>
        <h2>{getLocale("Extreme Adrenaline")}</h2>
        <p>{getLocale("Extreme Adrenaline Content")}</p>
      </Container>
      <Container className={styles.content}>
        {/* <div className={styles.heading}>
					<h6>{getLocale("Specials")} </h6>
					<h4>{getLocale("valueatitsbest")}</h4>
				</div> */}
        <Grid container spacing={1}>
          {/* <Grid item md={12} lg={4}>
						<div className={styles.card} onClick={handleOpen1}>
							<img src={snowPlus} alt="img" />
							<div className={styles.gradient}></div>
							<div>
								<p>{getLocale("Snow Plus Offer")}</p>
								<img src={leftArrow} alt="img" />
							</div>
						</div>
					</Grid> */}
          {/* <Grid item md={12} lg={4}>
						<div className={styles.card} onClick={handleOpen2}>
							<img src={`${lang === "en" ?  snowClassicOfferEn : snowClassicOfferAr}`} alt="img" />
							<div className={styles.gradient}></div>
							<div>
								<p>{getLocale("Snow Classic Offer")}</p>
								<img src={leftArrow} alt="img" />
							</div>
						</div>
					</Grid> */}
          {/* <Grid item md={12} lg={4}>
						<div className={styles.card} onClick={handleOpen3}>
							<img src={`${lang === "en" ?  snowPlusOfferEn : snowPlusOfferAr}`}  alt="img" />
							<div className={styles.gradient}></div>
							<div>
								<p>{getLocale("Snow Plus Offer")}</p>
								<img src={leftArrow} alt="img" />
							</div>
						</div>
					</Grid> */}
          {/* <Grid item md={12} lg={4}>
						<div className={styles.card} onClick={handleOpen4}>
							<img src={newOffer} alt="img" />
							<div className={styles.gradient}></div>
							<div>
								<p>{getLocale("Ski in 48 hours")}</p>
								<img src={leftArrow} alt="img" />
							</div>
						</div>
					</Grid> */}
        </Grid>
      </Container>
      <Packages />
      <SocialSection />
    </div>
  );
}

export default Offers;

import React, { useRef, useEffect, useState } from "react";
import styles from "./Slider.module.scss";
import { Container, Grid } from "@material-ui/core";
import SwiperSlider from "../../../General/swiper/swiper";
import * as imgs from "./images";
import { useSelector } from "react-redux";
import useLocale from "../../../../lang/useLocale";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Slider() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  const lang = useSelector((state) => state.lang);
  const { getLocale } = useLocale();
  const [autoplay, setautoplay] = useState({
    delay: 10000,
  });
  useEffect(() => {
    if (lang) {
      mySwiper.current.autoplay.stop();
      console.log(mySwiper.current.autoplay);
      setautoplay({
        delay: 10000,
      });
    }
  }, [lang]);
  const mySwiper = useRef();
  const settings = {
    arrows: true,
    loop: false,
    spaceBetween: 10,
    autoplay: autoplay,
    autoplayDisableOnInteraction: true,
    slidesPerView: 1,
    bullets: true,
    thumbs: true,
  };
  const slides = [
    {
      title: getLocale("Ski Slope"),
      subTitle: getLocale("activities"),
      description: getLocale("Ski Slopeexert"),
      img: imgs.slope,
    },
    {
      title: getLocale("Snowboarding"),
      subTitle: getLocale("activities"),
      description: getLocale("Snowboardingexert"),
      img: imgs.snowboarding,
    },
    {
      title: getLocale("Ski School"),
      subTitle: getLocale("activities"),
      description: getLocale("Ski Schoolexert"),
      img: imgs.school,
    },
    {
      title: getLocale("Bumpy Ride"),
      subTitle: getLocale("activities"),
      description: getLocale("Bumpy Rideexert"),
      img: imgs.bumbyroad,
    },
    {
      title: getLocale("Snake Ride"),
      subTitle: getLocale("activities"),
      description: getLocale("Snake Rideexert"),
      img: imgs.snakeride,
    },
    {
      title: getLocale("polarexpress"),
      subTitle: getLocale("activities"),
      description: getLocale("polarexpressexert"),
      img: imgs.polarexpress,
    },
    {
      title: getLocale("Bobsled"),
      subTitle: getLocale("activities"),
      description: getLocale("Bobsledexert"),
      img: imgs.bobsled,
    },
    {
      title: getLocale("Zorb Ball"),
      subTitle: getLocale("activities"),
      description: getLocale("Zorb Ballexert"),
      img: imgs.zorbball,
    },
    {
      title: getLocale("Snow Rocket"),
      subTitle: getLocale("activities"),
      description: getLocale("Snow Rocketexert"),
      img: imgs.snowrocket,
    },
    {
      title: getLocale("Snow Play Area"),
      subTitle: getLocale("activities"),
      description: getLocale("Snow Play Areaexert"),
      img: imgs.snowplayarea,
    },
    {
      title: getLocale("Snowflake Café"),
      subTitle: getLocale("activities"),
      description: getLocale("Snowflake Caféexert"),
      img: imgs.snowflake,
    },
    {
      title: getLocale("Ice Café"),
      subTitle: getLocale("activities"),
      description: getLocale("Ice Caféexert"),
      img: imgs.icedcafe,
    },
    {
      title: getLocale("Snow Penguins Encounters"),
      subTitle: getLocale("activities"),
      description: getLocale("Snow Penguins Encountersexert"),
      img: imgs.penguinencounter,
    },
    {
      title: getLocale("March of the Penguins"),
      subTitle: getLocale("activities"),
      description: getLocale("March of the Penguinsexert"),
      img: imgs.penguinmarch,
    },
    {
      title: getLocale("SnowPro Shop"),
      subTitle: getLocale("activities"),
      description: getLocale("SnowPro Shopeexert"),
      img: imgs.snowproshop,
    },
  ];
  return (
    <div className={`${styles.ContainerBackground}`}>
      <div data-aos="fade-up">
        <Container maxWidth="lg" className="aboutSlider">
          <SwiperSlider
            key={`${lang}-swiper`}
            settings={settings}
            mySwiper={mySwiper}
          >
            {slides.map((slide) => {
              return (
                <div
                  key={slide.title}
                  className={`${styles.packageSlide} swiper-slide`}
                >
                  <Grid
                    spacing={2}
                    container
                    direction={lang === "en" ? "row" : "row-reverse"}
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      md={6}
                      className={`${styles.slideData}`}
                    >
                      <Grid item xs={12}>
                        <p className={`${styles.subTitle}`}>{slide.subTitle}</p>
                      </Grid>
                      <Grid item xs={12}>
                        <p className={`${styles.Title}`}>{slide.title}</p>
                      </Grid>
                      <Grid item xs={12}>
                        <p className={`${styles.Description}`}>
                          {slide.description}
                        </p>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={6}
                      className={`${styles.slideImg}`}
                    >
                      <img src={slide.img} alt="img" />
                    </Grid>
                  </Grid>
                </div>
              );
            })}
          </SwiperSlider>
        </Container>
      </div>
    </div>
  );
}

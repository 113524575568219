import React, { useState, useRef, useEffect } from "react";
import { Container, Grid } from "@material-ui/core";
import styles from "./skiClubProgram.module.scss";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import exclamationCircle from "../../../../img/exclamation-circle.png";
import exclamationCircleSmall from "../../../../img/exclamation-circle-small.png";
import axios from "axios";
import useLocale from "../../../../lang/useLocale";
import AOS from "aos";
import "aos/dist/aos.css";

const SkiClubProgram = (props) => {
  const { getLocale } = useLocale();
  const animationElementRef = useRef();
  const lang = useSelector((state) => state.lang);
  const [openRegisterForm, setOpenRegisterForm] = useState(false);
  const { register, handleSubmit, errors } = useForm(); // initialize the hook
  const [ageError, setAgeError] = useState(false);
  const [ageErrorMessage, setAgeErrorMessage] = useState(
    getLocale("Please Enter Your Age")
  );
  const [submittingForm, setSubmittingForm] = useState();
  // const [animationElement,setAnimationElement]=useState();
  //   const [moveElement, setMoveElement] = useState(false);
  // const [forward,setForward]=useState(false);
  //     window.addEventListener('scroll', (event) => {

  //         if(animationElement){
  //             // console.log(animationElement)
  // if(window.pageYOffset >= (animationElement-100)){
  //     // console.log(animationElement-100 , ":",  window.pageYOffset)

  //     // console.log(animationElement , ":",  window.pageYOffset)
  //     // console.log("hi")
  //     // animationElementRef.current.classList.add("appear");
  //     setMoveElement(true);
  // }
  // else{
  //     // animationElementRef.current.classList.remove("appear");
  //     setMoveElement(false);
  // }
  //     }});
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  useEffect(() => {
    // console.log(window.location.href);
    if (window.location.href.includes("#register")) {
      setOpenRegisterForm(true);
    }
    // if(animationElementRef.current){
    //     // console.log(animationElementRef.current)
    //     window.onscroll=()=>{
    //         let top=animationElementRef.current?.getBoundingClientRect().top;
    //         // console.log(`t${top-100}` )
    //         if(top <= 500 ){
    //             // console.log("hi")
    //             setMoveElement(true);
    //         }
    //         else
    //         {
    //             setMoveElement(false);
    //     }
    //     }
    // }
  }, [animationElementRef]);
  const onSubmit = (data) => {
    // console.log(data)
    if (data.type === "Skiing") {
      if (data.age < 3) {
        setAgeError(true);
        setAgeErrorMessage(getLocale("Minimum age for Skiing is 3 years"));
      } else {
        setAgeError(false);
        setAgeErrorMessage(getLocale("Please Enter Your Age"));
        axios
          .post("https://ski-back.tacverse.com/api/club", data)
          .then((response) => {
            // console.log(response)
            setSubmittingForm(getLocale("Form Submitted Successfully"));
          })
          .catch((error) => {
            // console.log(error.response.data)
            setSubmittingForm(getLocale("Error"));
          });
      }
    } else {
      if (data.age < 8) {
        setAgeError(true);
        setAgeErrorMessage(
          getLocale("Minimum age for Snowboarding is 8 years")
        );
      } else {
        setAgeError(false);
        setAgeErrorMessage(getLocale("Please Enter Your Age"));
        axios
          .post("https://ski-back.tacverse.com/api/club", data)
          .then((response) => {
            // console.log(response)
            setSubmittingForm(getLocale("Form Submitted Successfully"));
          })
          .catch((error) => {
            // console.log(error.response.data)
            setSubmittingForm(getLocale("Error"));
          });
      }
    }
  };
  const registerForm = () => {
    // setForward(true);
    setOpenRegisterForm(true);
    // if(animationElementRef.current.hasClass("appear")){
    // console.log(animationElementRef.current);
    // }
  };

  return (
    <div
      role="img"
      aria-label="ski egypt"
      id="register"
      className={styles.skiClubProgramContainer}
    >
      <Container maxWidth="lg">
        {openRegisterForm ? (
          <Grid container>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={`${lang === "en" ? "LEFT" : "RTL"}`}
            >
              <Grid item md={9} xs={12}>
                <h2 className={styles.title}>
                  {getLocale("Ski Club Program")}
                </h2>
              </Grid>
              <Grid item md={9} xs={12}>
                <Grid container spacing={1}>
                  <Grid item md={6} xs={12}>
                    <div className={styles.inputContainer}>
                      <input
                        className={
                          errors.name &&
                          errors.name.type === "required" &&
                          `${styles.errorMessage}`
                        }
                        ref={register({ required: true })}
                        name="name"
                        type="name"
                        placeholder={`${lang === "en" ? "Name" : "الاسم"}`}
                      />
                      {errors.name && errors.name.type === "required" && (
                        <div className={styles.warningIcon}>
                          <img
                            src={exclamationCircle}
                            alt="error"
                            className={styles.imgError}
                          />
                          <div className={styles.errorText}>
                            <p>{getLocale("Please Enter Your Name")}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className={styles.inputContainer}>
                      <input
                        className={
                          errors.email &&
                          errors.email.type === "required" &&
                          `${styles.errorMessage}`
                        }
                        ref={register({ required: true })}
                        name="email"
                        type="email"
                        placeholder={`${
                          lang === "en" ? "Email Address" : "البريد الألكتروني*"
                        }`}
                      />
                      {errors.email && errors.email.type === "required" && (
                        <div className={styles.warningIcon}>
                          <img
                            src={exclamationCircle}
                            alt="error"
                            className={styles.imgError}
                          />
                          <div className={styles.errorText}>
                            <p>{getLocale("Please Enter Your Email")}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <div className={styles.inputContainer}>
                      <input
                        className={
                          errors.age &&
                          errors.age.type === "required" &&
                          `${styles.errorMessage}`
                        }
                        ref={register({ required: true })}
                        name="age"
                        type="age"
                        placeholder={`${lang === "en" ? "Age" : "السن"}`}
                      />
                      {(errors.age && errors.age.type === "required") ||
                      ageError ? (
                        <div className={styles.warningIcon}>
                          <img
                            src={exclamationCircle}
                            alt="error"
                            className={styles.imgError}
                          />
                          <div className={styles.errorText}>
                            <p>{ageErrorMessage}</p>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className={styles.inputContainer}>
                      <input
                        className={
                          errors.phone &&
                          errors.phone.type === "required" &&
                          `${styles.errorMessage}`
                        }
                        ref={register({ required: true })}
                        name="phone"
                        type="text"
                        placeholder={`${
                          lang === "en" ? "Phone Number" : "رقم الهاتف"
                        }`}
                      />
                      {errors.phone && errors.phone.type === "required" && (
                        <div className={styles.warningIcon}>
                          <img
                            src={exclamationCircle}
                            alt="error"
                            className={styles.imgError}
                          />
                          <div className={styles.errorText}>
                            <p>{getLocale("Please Enter Your Phone")}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <div className={styles.inputContainer}>
                      <select
                        ref={register({ required: true })}
                        className={
                          errors.choose &&
                          errors.choose.type === "required" &&
                          `${styles.errorMessage}`
                        }
                        defaultValue=""
                        name="type"
                      >
                        <option value="" disabled>
                          {getLocale("Skiing/Snowboarding")}
                        </option>
                        <option value="Skiing">{getLocale("Skiing")}</option>
                        <option value="Snowboarding">
                          {getLocale("Snowboarding")}
                        </option>
                      </select>
                      {errors.type && errors.type.type === "required" && (
                        <div className={styles.warningIcon}>
                          <img
                            src={exclamationCircle}
                            alt="error"
                            className={styles.imgError}
                          />
                          <div className={styles.errorText}>
                            <p>
                              {getLocale("Please select skiing/snowboarding")}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={9} xs={12}>
                <Grid container alignItems="center">
                  <Grid item md={12} xs={12}>
                    <div className={styles.inputContainer}>
                      <p
                        className={styles.note}
                        style={{ paddingBottom: "15px" }}
                      >
                        <input
                          ref={register({ required: true })}
                          name="discovery"
                          type="checkbox"
                          value="1"
                        />
                        <span className={styles.labelCheckbox}>
                          {" "}
                          {getLocale("I have completed the Discovery Lesson.")}
                        </span>
                      </p>
                      {errors.discovery &&
                        errors.discovery.type === "required" && (
                          <div
                            style={{ display: "inline-block" }}
                            className={styles.warningIcon}
                          >
                            <img
                              className={styles.smallWarning}
                              src={exclamationCircleSmall}
                              alt="small warning"
                            />
                            <div className={styles.errorText}>
                              <p>
                                {getLocale(
                                  "You have to complete the Discovery Lesson first"
                                )}
                              </p>
                            </div>
                          </div>
                        )}
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <button type="SUBMIT" className={styles.join}>
                      {getLocale("Register Now")}
                    </button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={9} xs={12}>
                <ol className={styles.listWarningMessage}>
                  <li>{getLocale("All fields are required.")}</li>
                  <li>
                    {getLocale(
                      "Minimum age for Skiing lessons is 3 years and 8 years for Snowboarding lessons"
                    )}{" "}
                  </li>
                  <li>
                    {getLocale(
                      "You have to complete the Discovery Lesson first. Find your lesson"
                    )}
                    <p
                      className={styles.linkToChooseYourLesson}
                      onClick={props.click}
                    >
                      {" "}
                      {getLocale("here!")}
                    </p>
                  </li>
                </ol>
              </Grid>
              <Grid item md={9} xs={12}>
                <p> {submittingForm} </p>
              </Grid>
            </form>
          </Grid>
        ) : (
          <Grid
            container
            className={`${styles.skiClupProgramContainerForAnimation} 
            //   moveElement ? styles.appear : null
            // 
            `}
          >
            <div data-aos="fade-up">
              <Grid item md={12} xs={12}>
                <Grid item md={7} xs={12}>
                  <p className={styles.intro}>{getLocale("Introducing")}</p>
                  <h2 className={styles.title}>
                    {getLocale("Ski Club Program")}
                  </h2>
                  <p>
                    <span className={styles.date}>
                      {getLocale("Registration Starts from April 16th")}
                      <span className={styles.line}>———</span>
                      {getLocale("April 23rd")}
                    </span>
                    {getLocale(
                      "We’re excited to announce this 2021’s “Ski Club” Program. Ski School is offering the “Ski Club” program for those who want to become professional skiers and snowboarders within an intense program. When you complete your discovery lesson and qualify for beginner level. You can enroll in the “Ski Club” program that includes: 10 sessions ( 2 hours per session), 1 session per week. And emerge a pro skier.Program available for all ages starting 3 years for skiing and 8 years for snowboarding"
                    )}
                  </p>
                </Grid>
              </Grid>
              <Grid item md={7} xs={12}>
                <Grid container alignItems="center">
                  <Grid item md={5} xs={12} className={styles.btnContainer}>
                    <button className={styles.join} onClick={registerForm}>
                      {getLocale("JOIN THE CLUB")}
                    </button>
                  </Grid>
                  <Grid item md={7} xs={12} className={styles.priceContainer}>
                    <p className={styles.price}>
                      {getLocale("2,100")}
                      <span>/{getLocale("Per Person")}</span>
                    </p>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <p className={styles.note} style={{ marginTop: "20px" }}>
                      {getLocale(
                        "*Participant needs to have completed a discovery lesson prior to enrollment"
                      )}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        )}
      </Container>
    </div>
  );
};
export { SkiClubProgram };

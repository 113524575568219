import React, { useEffect } from 'react'
import styles from '../skiSchool.module.scss'
import { Container } from '@material-ui/core'
import Search from './search'
import useLocale from '../../../lang/useLocale'
import AOS from "aos";
import "aos/dist/aos.css";

export default function School(props) {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true
        });
    }, []);
    const { getLocale } = useLocale()
    return (
        <div ref={props.refI} className={`${styles.school} skiShcool`}>
            <Container maxWidth='lg'>
                <div data-aos="fade-up">
                    <h2 className={styles.title}>
                        {getLocale('LESSONS')}
                    </h2>
                    <Search />
                    <div className={styles.note}>
                        <p>
                            {getLocale('Any additional guest')}
                        </p>
                        <p>
                            {getLocale('Minimum age')}
                        </p>
                    </div>
                </div>
            </Container>
        </div>
    )
}
import React, { useEffect, useRef } from "react";
import SwiperSlider from "../../General/swiper/swiper";
import { Container, Grid } from "@material-ui/core";
import snow from "../../../img/snowPackages.JPG";
import styles from "../packages.module.scss";
// import optionlist1 from "../../../img/optionlist1.jpg";
import table1 from "../../../img/table1.jpg";
import table2 from "../../../img/table2.JPG";
import table3 from "../../../img/table3.jpg";
import table4 from "../../../img/table4.jpg";
import table5 from "../../../img/table5.jpg";
import table6 from "../../../img/table6.jpg";
import table7 from "../../../img/table7.jpg";
import locker from "../../../img/locker.jpg";
import gloves from "../../../img/gloves.jpg";
import hotchoclate from "../../../img/hotchoclate.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import useLocale from "../../../lang/useLocale";
import { useSelector } from "react-redux";

export default function Comparison() {
	const { getLocale } = useLocale();
	const lang = useSelector((state) => state.lang);
	const mySwiper = useRef();
	const myElement=useRef();
	useEffect(()=>{
		// add and remove class active for arrow next and prev on scroll
		if(myElement.current){
			let myElementCurrent=myElement.current
			window.onscroll=()=>{
				let top=myElementCurrent.getBoundingClientRect().top;
				let bottom=myElementCurrent.getBoundingClientRect().bottom;
				if(top <= 300 && bottom >= 450 ){
					// console.log("in")
					myElementCurrent.querySelector('.swiper-button-next').classList.add("active")
					myElementCurrent.querySelector('.swiper-button-prev').classList.add("active")
				}
				else
				{
				// console.log("out")
				myElementCurrent.querySelector('.swiper-button-next').classList.remove("active")
				myElementCurrent.querySelector('.swiper-button-prev').classList.remove("active")
			}
			}
		}
	},[myElement])
	const settings = {
		arrows: true,
		loop: false,
		spaceBetween: 10,
		// autoplay: {
		// 	delay: 10000,
		// },
		breakpoints: {
			320: {
				slidesPerView: 1.1,
			},
			768: {
				slidesPerView: 1.1,
			},
			1025: {
				slidesPerView: 3,
			},
		},
		autoplayDisableOnInteraction: true,
		bullets: false,
	};
	return (
		<Container className={styles.comparison}>
			<Grid container spacing={1} direction={lang === "en" ? "row" : "row-reverse"}>
				<Grid item xs={6} md={4} className={styles.snow}>
					<div className={styles.snowWrap}>
						<Grid container justify="center" alignItems="center">
							<img src={snow} alt="snow" />
						</Grid>
						<h6>{getLocale("What's included")}</h6>
					</div>
					<Grid container alignItems="center">
						<Grid item container alignItems="center" justify="center" className={styles.row}>
							<Grid item xs={4}>
								<img src={table1} alt="img" />
							</Grid>
							<Grid item xs={8}>
								<div className={styles.snowDesc}>{getLocale("Kids rides")}</div>
							</Grid>
						</Grid>
						<Grid item container alignItems="center" justify="center" className={styles.row}>
							<Grid item xs={4}>
								<img src={table2} alt="img" />
							</Grid>
							<Grid item xs={8}>
								<div className={styles.snowDesc}>{getLocale("Tubings")}</div>
							</Grid>
						</Grid>
						<Grid item container alignItems="center" justify="center" className={styles.row}>
							<Grid item xs={4}>
								<img src={table3} alt="img" />
							</Grid>
							<Grid item xs={8}>
								<div className={styles.snowDesc}>{getLocale("Zorb Ball")}</div>
							</Grid>
						</Grid>
						<Grid item container alignItems="center" justify="center" className={styles.row}>
							<Grid item xs={4}>
								<img src={table4} alt="تزحلق مول مصر" />
							</Grid>
							<Grid item xs={8}>
								<div className={styles.snowDesc}>{getLocale("Snow Rocket")}</div>
							</Grid>
						</Grid>
						<Grid item container alignItems="center" justify="center" className={styles.row}>
							<Grid item xs={4}>
								<img src={table5} alt="العاب تزلج على الثلج" />
							</Grid>
							<Grid item xs={8}>
								<div className={styles.snowDesc}>{getLocale("60 min ski or snowboard discovery lesson")}</div>
							</Grid>
						</Grid>
						<Grid item container alignItems="center" justify="center" className={styles.row}>
							<Grid item xs={4}>
								<img src={table6} alt="تزلج على الجليد" />
							</Grid>
							<Grid item xs={8}>
								<div className={styles.snowDesc}>{getLocale("2 hours slope access")}</div>
							</Grid>
						</Grid>
						<Grid item container alignItems="center" justify="center" className={styles.row}>
							<Grid item xs={4}>
								<img src={table7} alt="بطريق سكي ايجيبت" />
							</Grid>
							<Grid item xs={8}>
								<div className={styles.snowDesc}>{getLocale("Penguin Encounter")}</div>
							</Grid>
						</Grid>
						<Grid item container alignItems="center" justify="center" className={styles.row}>
							<Grid item xs={4}>
								<img src={locker} alt="img" />
							</Grid>
							<Grid item xs={8}>
								<div className={styles.snowDesc}>{getLocale("Free Locker")}</div>
							</Grid>
						</Grid>
						<Grid item container alignItems="center" justify="center" className={styles.row}>
							<Grid item xs={4}>
								<img src={gloves} alt="img" />
							</Grid>
							<Grid item xs={8}>
								<div className={styles.snowDesc}>{getLocale("Free Gloves")}</div>
							</Grid>
						</Grid>
						{/* <Grid item container alignItems='center' justify='center'  className={styles.row}>
                            <Grid item xs={4}>
                                <img src={gloves} alt='img'/>
                            </Grid>
                            <Grid item xs={8}>
                                <div className={styles.snowDesc}>{getLocale("Free Handwarmers")}</div>
                            </Grid>
                        </Grid> */}
						<Grid item container alignItems="center" justify="center" className={styles.row}>
							<Grid item xs={4}>
								<img src={hotchoclate} alt="img" />
							</Grid>
							<Grid item xs={8}>
								<div className={styles.snowDesc}>{getLocale("Free Hot Chocolate")}</div>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid xs={6} md={8} item style={{ paddingTop: "0px" }} ref={myElement}>
					<SwiperSlider settings={settings}  mySwiper={mySwiper} containerClass={styles.swiperContainerClass}>
						<div className={`${styles.packageSlide} swiper-slide`}>
							<div className={styles.header}>
								<h2>{getLocale("snowclassic")}</h2>
								<h6>300 {getLocale("LE")}</h6>
								<a
									href={`https://booking.skiegy.com/${lang}/snow-park/booking/snow-classic`}
									target="_self"
									rel="noopener noreferrer"
								>
									{getLocale("BOOK NOW")}
								</a>
							</div>
							<div className={styles.item}>
								<FontAwesomeIcon icon={faCheck} />
								<span>{getLocale("Unlimited")}</span>
							</div>
							<div className={styles.item}>
								<FontAwesomeIcon icon={faCheck} />
								<span>{getLocale("Unlimited")}</span>
							</div>
							<div className={styles.item}>
								<FontAwesomeIcon icon={faCheck} />
								<span>{getLocale("Unlimited")}</span>
							</div>
							<div className={styles.item}>
								<FontAwesomeIcon icon={faCheck} />
								<span>{getLocale("Unlimited")}</span>
							</div>
							<div className={styles.item}>
								<FontAwesomeIcon icon={faTimes} />
								<span>{getLocale("Notavailable")}</span>
							</div>
							<div className={styles.item}>
								<FontAwesomeIcon icon={faTimes} />
								<span>{getLocale("Notavailable")}</span>
							</div>
							<div className={styles.item}>
								<FontAwesomeIcon icon={faTimes} />
								<span>{getLocale("Notavailable")}</span>
							</div>
							<div className={styles.item}>
								<FontAwesomeIcon icon={faTimes} />
								<span>{getLocale("notFree")}</span>
							</div>
							<div className={styles.item}>
								<FontAwesomeIcon icon={faTimes} />
								<span>{getLocale("notFree")}</span>
							</div>
							{/* <div className={styles.item}>
                                <FontAwesomeIcon icon={faTimes} /><span>{getLocale("notFree")}</span>
                            </div> */}
							<div className={styles.item}>
								<FontAwesomeIcon icon={faTimes} />
								<span>{getLocale("notFree")}</span>
							</div>
						</div>
						<div className={`${styles.packageSlide} swiper-slide`}>
							<div className={styles.header}>
								<h2>{getLocale("snowplus")}</h2>
								<h6>450 {getLocale("LE")}</h6>
								<a
									href={`https://booking.skiegy.com/${lang}/snow-park/booking/snow-plus`}
									target="_self"
									rel="noopener noreferrer"
								>
									{getLocale("BOOK NOW")}
								</a>
							</div>
							<div className={styles.item}>
								<FontAwesomeIcon icon={faCheck} />
								<span>{getLocale("Unlimited")}</span>
							</div>
							<div className={styles.item}>
								<FontAwesomeIcon icon={faCheck} />
								<span>{getLocale("Unlimited")}</span>
							</div>
							<div className={styles.item}>
								<FontAwesomeIcon icon={faCheck} />
								<span>{getLocale("Unlimited")}</span>
							</div>
							<div className={styles.item}>
								<FontAwesomeIcon icon={faCheck} />
								<span>{getLocale("Unlimited")}</span>
							</div>
							<div className={styles.item}>
								{/* <FontAwesomeIcon icon={faCheck} /> */}
								<span>{getLocale("selectone")}</span>
							</div>
							<div className={styles.item}>
								{/* <FontAwesomeIcon icon={faCheck} /> */}
								<span>{getLocale("selectone")}</span>
							</div>
							<div className={styles.item}>
								{/* <FontAwesomeIcon icon={faCheck} /> */}
								<span>{getLocale("selectone")}</span>
							</div>
							<div className={styles.item}>
								<FontAwesomeIcon icon={faCheck} />
								<span>{getLocale("Free")}</span>
							</div>
							<div className={styles.item}>
								<FontAwesomeIcon icon={faTimes} />
								<span>{getLocale("notFree")}</span>
							</div>
							{/* <div className={styles.item}>
                                <FontAwesomeIcon icon={faCheck} /><span>{getLocale("Free")}</span>
                            </div> */}
							<div className={styles.item}>
								<FontAwesomeIcon icon={faTimes} />
								<span>{getLocale("notFree")}</span>
							</div>
						</div>
						<div className={`${styles.packageSlide} swiper-slide`}>
							<div className={styles.header}>
								<h2>{getLocale("snowpremium")}</h2>
								<h6>600 {getLocale("LE")}</h6>
								<a
									href={`https://booking.skiegy.com/${lang}/slope/booking/snow-premium`}
									target="_self"
									rel="noopener noreferrer"
								>
									{getLocale("BOOK NOW")}
								</a>
{/* <button className={styles.book}>{getLocale("Booking Unavailable")}</button> */}
							</div>
							<div className={styles.item}>
								<FontAwesomeIcon icon={faCheck} />
								<span>{getLocale("Unlimited")}</span>
							</div>
							<div className={styles.item}>
								<FontAwesomeIcon icon={faCheck} />
								<span>{getLocale("Unlimited")}</span>
							</div>
							<div className={styles.item}>
								<FontAwesomeIcon icon={faCheck} />
								<span>{getLocale("Unlimited")}</span>
							</div>
							<div className={styles.item}>
								<FontAwesomeIcon icon={faCheck} />
								<span>{getLocale("Unlimited")}</span>
							</div>
							<div className={styles.item}>
								{/* <FontAwesomeIcon icon={faCheck} /> */}
								<span>{getLocale("selectone")}</span>
							</div>
							<div className={styles.item}>
								{/* <FontAwesomeIcon icon={faCheck} /> */}
								<span>{getLocale("selectone")}</span>
							</div>
							<div className={styles.item}>
								<FontAwesomeIcon icon={faCheck} />
								<span>{getLocale("available")}</span>
							</div>
							<div className={styles.item}>
								<FontAwesomeIcon icon={faCheck} />
								<span>{getLocale("Free")}</span>
							</div>
							<div className={styles.item}>
								<FontAwesomeIcon icon={faCheck} />
								<span>{getLocale("Free")}</span>
							</div>
							{/* <div className={styles.item}>
                                <FontAwesomeIcon icon={faCheck} /><span>{getLocale("Free")}</span>
                            </div> */}
							<div className={styles.item}>
								<FontAwesomeIcon icon={faCheck} />
								<span>{getLocale("Free")}</span>
							</div>
						</div>
					</SwiperSlider>
				</Grid>
			</Grid>
		</Container>
	);
}

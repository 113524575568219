import React from 'react'
import styles from './skiSchoolHeaderText.module.scss'
function skiSchoolHeaderText(props) {
    return (
        <div className={`${styles.HeaderText}`}>
                    <p className={`${styles.subTitle}`}>{props.title}</p>
                    <p className={`${styles.Title}`}>{props.subtitle}</p>
                    <p className={`${styles.description}`}>{props.description}</p>
                    </div>
    )
}

export default skiSchoolHeaderText;

import React from "react";
import styles from "./penguins.module.scss";
// import Banner from '../General/headerPanel/headerPanel'
// import penguinsBanner from '../../img/penguinsBanner.jpg'
// import { Container } from '@material-ui/core'
import SocialSection from "../General/social-section/social-section";
// import OurAttractions from '../General/Our-Attractions/Our-Attractions'
import PenguinsTickets from "./subComponents/penguinTickets";
import March from "./subComponents/video";
import MeetPenguins from "./subComponents/meetPenguins";
import MeetTrainers from "./subComponents/meetTrainers";
import VideoBasic from "./video/video";
import videoImg from "../../img/penguinsVid.jpg";
import { Container, Grid } from "@material-ui/core";
import useLocale from "../../lang/useLocale";
import {Helmet} from "react-helmet";

export default function Penguins() {
	const { getLocale } = useLocale();
	return (
		<div className={styles.pricing}>
			  <Helmet>
    <meta
      name="description"
      content={getLocale("Enjoy the snow penguin program & met  Sultan, our star penguin, at the ski Egypt in the snow city mall of Egypt. Visit ski Egypt now to now prices")}
    />
      <title>{getLocale("Enjoy the Snow Penguin Program in Mall of Egypt | SKI Egypt")}</title>
            </Helmet>
			<VideoBasic title="THE PENGUINS' JOURNEY" img={videoImg} videoUrl="https://www.youtube.com/embed/Mdr-Cof6wN4" />
			<Container className={styles.penguinsDesc}>
				<Grid container justify="center" alignItems="center">
					<Grid item xs={10}>
						<h2>{getLocale("PENGUINS")}</h2>
						<p>{getLocale("PENGUINSpageexert")}</p>
					</Grid>
				</Grid>
			</Container>
			<March />
			<MeetPenguins />
			<MeetTrainers />
			<PenguinsTickets />
			<SocialSection />
		</div>
	);
}

import React, { useRef } from "react";
import styles from "./tripadvisor.module.scss";
import "./tripadvisor.scss";
import { Container, Grid } from "@material-ui/core";
import Swiper from "../../swiper/swiper";
import imgTrip from "../../../../img/img-trip.png";
// import tripLogo from "../../../../img/tripLogo.png";
// import cardThumb from "../../../../img/cardThumb.png";
function Tripadvisor() {
  const settings = {
    loop: true,
    spaceBetween: 2,
    // arrows: true,
    autoplay: {
      delay: 3000,
    },
    autoplayDisableOnInteraction: true,
  };
  const mySwiperRef = useRef(null);

  return (
    <div className={`${styles.containerTripAdvisor}`}>
      <div className={`${styles.containerBackgroundColor}`}>
        <Container maxWidth="lg">
          <Grid container className={`${styles.containerAllGrids}`}>
            <Grid
              container
              item
              xs={12}
              md={6}
              className={`${styles.containerLeft}`}
            >
              {/* <Grid container item xs={12}>
                <img
                  alt="logo"
                  src={tripLogo}
                  className={`${styles.tripLogo}`}
                />
              </Grid> */}
              <Grid container item xs={12}>
                <Swiper
                  mySwiper={mySwiperRef}
                  settings={settings}
                  style={{
                    // height: "128px",
                  }}
                >
                  {/* <div className={`${styles.slide} swiper-slide`}>
                    <div>
                      <div className={styles.cardInfo}>
                        <img
                          src={cardThumb}
                          alt="cardThumb"
                          className={styles.cardImg}
                        />
                        <span className={`${styles.cardMessage}`}>
                          Great time there has spent with my family , new
                          experience, perfect safety, nicely staff , recommended
                          for families.
                        </span>
                      </div>
                    </div>
                  </div> */}
{/* <div className={`${styles.slide} swiper-slide`}>
                    <div>
                      <div className={styles.cardInfo}>
                        <img
                          src={cardThumb}
                          alt="cardThumb"
                          className={styles.cardImg}
                        />
                        <span className={`${styles.cardMessage}`}>
                          Great time there has spent with my family , new
                          experience, perfect safety, nicely staff , recommended
                          for families.
                        </span>
                      </div>
                    </div>
                  </div> */}
                  {/* // */}
                  <div className={`${styles.slide} swiper-slide`}>
                    <div>
                      <div className={styles.cardInfo}>
                        <iframe className={`${styles.cardMessage}`} title="feedback" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fchemlilo87.li%2Fposts%2F3530435067054439&width=500&show_text=true&height=156&appId"  style={{border:'none',overFlow:'hidden',minHeight:"168px"}} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay"></iframe>
                       
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.slide} swiper-slide`}>
                    <div>
                      <div className={styles.cardInfo}>      
                      <iframe title="feedback2" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fdina.badr.393%2Fposts%2F3716462888377933&width=500&show_text=true&height=194&appId" style={{border:'none',overFlow:'hidden',minHeight:'206px'}} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay"></iframe>                       
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.slide} swiper-slide`}>
                    <div>
                      <div className={styles.cardInfo}>      
                      <iframe title="feedback16" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fhussein.rizk.7127%2Fposts%2F1161974264178832&width=500&show_text=true&height=137&appId" style={{border:'none',overFlow:'hidden',minHeight:'155px'}} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay"></iframe>                       
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.slide} swiper-slide`}>
                    <div>
                      <div className={styles.cardInfo}>      
                      <iframe title="feedback3" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fiman.ezzo%2Fposts%2F10158545846016605&width=500&show_text=true&height=156&appId" style={{border:'none',overFlow:'hidden',minHeight:'186px'}} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay"></iframe>                       
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.slide} swiper-slide`}>
                    <div>
                      <div className={styles.cardInfo}>      
                      <iframe title="feedback20" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Farmia.malaty%2Fposts%2F10221011799628496&width=500&show_text=true&height=156&appId" style={{border:'none',overFlow:'hidden',minHeight:'186px'}} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay"></iframe>                       
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.slide} swiper-slide`}>
                    <div>
                      <div className={styles.cardInfo}>      
                      <iframe title="feedback4" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fmenna.essam25%2Fposts%2F2791640407753889&width=500&show_text=true&height=137&appId" style={{border:'none',overFlow:'hidden',minHeight:'158px'}} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay"></iframe>                       
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.slide} swiper-slide`}>
                    <div>
                      <div className={styles.cardInfo}>      
                      <iframe title="feedback5" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Ffranziska.engel.52%2Fposts%2F10220270170957427&width=500&show_text=true&height=194&appId" style={{border:'none',overFlow:'hidden',minHeight:'207px'}} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay"></iframe>                       
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.slide} swiper-slide`}>
                    <div>
                      <div className={styles.cardInfo}>      
                      <iframe title="feedback17" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fjoly.samir%2Fposts%2F1633612333445979&width=500&show_text=true&height=156&appId" style={{border:'none',overFlow:'hidden',minHeight:'174px'}} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay"></iframe>                       
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.slide} swiper-slide`}>
                    <div>
                      <div className={styles.cardInfo}>      
                      <iframe title="feedback15" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fsahr.ahmed.3572%2Fposts%2F1611469389014603&width=500&show_text=true&height=137&appId" style={{border:'none',overFlow:'hidden',minHeight:'158px'}} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay"></iframe>                       
                      </div>
                    </div>
                  </div>
                
                  <div className={`${styles.slide} swiper-slide`}>
                    <div>
                      <div className={styles.cardInfo}>      
                      <iframe title="feedback6" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fnana.wael.1291%2Fposts%2F3042156955871500&width=500&show_text=true&height=156&appId" style={{border:'none',overFlow:'hidden',minHeight:'168px'}} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay"></iframe>                       
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.slide} swiper-slide`}>
                    <div>
                      <div className={styles.cardInfo}>      
                      <iframe title="feedback7" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fbasma.yaqoot%2Fposts%2F4229431300432323&width=500&show_text=true&height=156&appId" style={{border:'none',overFlow:'hidden',minHeight:'168px'}} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay"></iframe>                       
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.slide} swiper-slide`}>
                    <div>
                      <div className={styles.cardInfo}>      
                      <iframe title="feedback18" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fahmad.hussam.549%2Fposts%2F10158032284015127&width=500&show_text=true&height=175&appId" style={{border:'none',overFlow:'hidden',minHeight:'193px'}} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay"></iframe>                       
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.slide} swiper-slide`}>
                    <div>
                      <div className={styles.cardInfo}>      
                      <iframe title="feedback8" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fbecca.pennington.33%2Fposts%2F10217935336244894&width=500&show_text=true&height=175&appId" style={{border:'none',overFlow:'hidden',minHeight:'187px'}} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay"></iframe>                       
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.slide} swiper-slide`}>
                    <div>
                      <div className={styles.cardInfo}>      
                      <iframe title="feedback9" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Freem.naseem%2Fposts%2F10164571863610405&width=500&show_text=true&height=181&appId" style={{border:'none',overFlow:'hidden',minHeight:'193px'}} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay"></iframe>                       
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.slide} swiper-slide`}>
                    <div>
                      <div className={styles.cardInfo}>      
                      <iframe title="feedback10" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Ftarek.etribi%2Fposts%2F10164305576955445&width=500&show_text=true&height=181&appId" style={{border:'none',overFlow:'hidden',minHeight:'193px'}} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay"></iframe>                       
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.slide} swiper-slide`}>
                    <div>
                      <div className={styles.cardInfo}>      
                      <iframe title="feedback19" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2F2AnadoOdOo%2Fposts%2F1388337758007107&width=500&show_text=true&height=156&appId" style={{border:'none',overFlow:'hidden',minHeight:'174px'}} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay"></iframe>                       
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.slide} swiper-slide`}>
                    <div>
                      <div className={styles.cardInfo}>      
                      <iframe title="feedback11" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Felda.ganzan%2Fposts%2F2604936849768232&width=500&show_text=true&height=156&appId" style={{border:'none',overFlow:'hidden',minHeight:'168px'}} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay"></iframe>                       
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.slide} swiper-slide`}>
                    <div>
                      <div className={styles.cardInfo}>      
                      <iframe title="feedback12" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fseham.ahmed.908%2Fposts%2F2815882858447155&width=500&show_text=true&height=137&appId" style={{border:'none',overFlow:'hidden',minHeight:'158px'}} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay"></iframe>                       
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.slide} swiper-slide`}>
                    <div>
                      <div className={styles.cardInfo}>      
                      <iframe title="feedback13" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fkirs.koka%2Fposts%2F4877747595599583&width=500&show_text=true&height=137&appId" style={{border:'none',overFlow:'hidden',minHeight:'155px'}} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay"></iframe>                       
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.slide} swiper-slide`}>
                    <div>
                      <div className={styles.cardInfo}>      
                      <iframe title="feedback14" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fselvana.safwat.35%2Fposts%2F2804089756525694&width=500&show_text=true&height=194&appId" style={{border:'none',overFlow:'hidden',minHeight:'212px'}} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay"></iframe>                       
                      </div>
                    </div>
                  </div>
                  {/* ////////// */}
                  <div className={`${styles.slide} swiper-slide`}>
                    <div>
                      <div className={styles.cardInfo}>      
                      <iframe title="feedback15" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3D299079325269030%26id%3D100055008945412&width=500&show_text=true&height=174&appId" style={{border:'none',overFlow:'hidden',minHeight:'174px'}} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>                       
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.slide} swiper-slide`}>
                    <div>
                      <div className={styles.cardInfo}>      
                      <iframe title="feedback16" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fchemlilo87.li%2Fposts%2F3530435067054439&width=500&show_text=true&height=168&appId" style={{border:'none',overFlow:'hidden',minHeight:'168px'}} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>                       
                      </div>
                    </div>
                  </div>
                   <div className={`${styles.slide} swiper-slide`}>
                    <div>
                      <div className={styles.cardInfo}>      
                      <iframe title="feedback17" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fselvana.safwat.35%2Fposts%2F2804089756525694&width=500&show_text=true&height=212&appId" style={{border:'none',overFlow:'hidden',minHeight:'212px'}} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>                       
                      </div>
                    </div>
                  </div>
                   <div className={`${styles.slide} swiper-slide`}>
                    <div>
                      <div className={styles.cardInfo}>      
                      <iframe title="feedback18" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fahmed.nadeem.921%2Fposts%2F10159525349886424&width=500&show_text=true&height=225&appId" style={{border:'none',overFlow:'hidden',minHeight:'225px'}} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>                       
                      </div>
                    </div>
                  </div>
               
            
                 
                 
                </Swiper>
              </Grid>
            </Grid>
            {/* <Grid container item xs={12} md={1}></Grid> */}

            <Grid
              container
              item
              xs={12}
              md={6}
              justify="center"
              alignItems="flex-end"
              className={`${styles.containerImg}`}
            >
              <img src={imgTrip} alt="logo" />
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}

export default Tripadvisor;

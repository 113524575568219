import React from "react";
import ReactDOM from "react-dom";
import "./index.sass";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore } from "redux";
import rootReducer from "./redux/reducers";
import middlewares from "./redux/middlewares";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";


export const store = createStore(rootReducer, middlewares);
const theme = createMuiTheme({
	//   breakpoints: {
	//     values: {
	//       lg: 1680
	//     }
	//   }
	spacing: 24,
});

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter >
				<ThemeProvider theme={theme}>
					<App />
				</ThemeProvider>
			</BrowserRouter>
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

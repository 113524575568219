import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import "./popup.scss";
import close from "../../../img/close.svg";

export default function Popup(props) {
	// const { getLocale } = useLocale()

	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};


	return (
		<div>
			<Button variant="outlined" color="primary" onClick={handleClickOpen} className={props.popupTriggerClass}>
				{props.popupTriggerTitle}
			</Button>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
				maxWidth={props.dialogWidth ? props.width : "lg"}
				className="containerPopup"
			>
				<DialogTitle id="form-dialog-title">{props.popupTitle}</DialogTitle>
				<DialogContent>{props.children}</DialogContent>
				<DialogActions>
					{!props?.hideClose && <img src={props.imgClose ? props.imgClose : close} alt="img" onClick={handleClose} className={props.closePopupStyle ? props.closePopupStyle : "closePopup"} />}
					{/* <Button onClick={handleClose} style={{color:'red'}}>
            {getLocale('Close')}
          </Button> */}
				</DialogActions>
			</Dialog>
		</div>
	);
}

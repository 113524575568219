import React, { useEffect } from "react";
import styles from "./headerPanel.module.scss";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";

const HeaderPanel = (props) => {
  const lang = useSelector((state) => state.lang);

  useEffect(() => {
    reportWindowSize();
  }, []);

  const reportWindowSize = () => {
    if (window.innerWidth > 1024) {
      document.getElementById("containerHeader").style.height =
        window.innerHeight - 140 + "px";
      document.getElementById("containerHeaderImg").style.height =
        window.innerHeight - 140 + "px";
    } else {
      document.getElementById("containerHeader").style.height =
        window.innerHeight - 70 + "px";
      document.getElementById("containerHeaderImg").style.height =
        window.innerHeight - 70 + "px";
    }
  };
  window.addEventListener("resize", reportWindowSize);
  console.log(window.innerHeight);
  return (
    <div className={`${styles.containerHeaderPanel}`} id="containerHeader">
      <div className={`${styles.containerPanal}`}>
        <img
          id="containerHeaderImg"
          src={props.panal}
          alt={props.alt ? props.alt : "panal"}
          className={props.className}
        />
      </div>
      {props.containerTextCenter ? (
        <div className={`${styles.containerTextCenter}`}>
          <p className={`${styles.title} ${styles.titleCentered}`}>
            {props.title}
          </p>
        </div>
      ) : null}

      {props.containerCard ? (
        <div className={`${styles.containerTitel}`}>
          <Grid container justify={lang === "en" ? "flex-start" : "flex-end"}>
            <Grid item md={8}>
              <p className={`${styles.subTitle}`}>{props.subTitle}</p>
              {!props.hideCardTitle ? (
                <p className={`${styles.title}`}>{props.title}</p>
              ) : null}
              <p className={`${styles.description}`}>{props.description}</p>
            </Grid>
          </Grid>
        </div>
      ) : null}
    </div>
  );
};
export default HeaderPanel;

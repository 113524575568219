import React, { useEffect } from "react";
import { Container, Grid } from "@material-ui/core";
import styles from "./professionalPackages.module.scss";
import packagesImg1 from "../../../img/freestyle-pass-package.png";
import useLocale from "../../../lang/useLocale";
import { Registration } from './registration/registeration';
import AOS from "aos";
import "aos/dist/aos.css";
function FreeStylePackage() {
	const { getLocale } = useLocale();
	useEffect(() => {
		AOS.init({
			duration: 1000,
			once: true
		});
	}, []);

	return (
		<Container maxWidth={false} className={styles.professionalPackages}>
			<Grid container justify="center" className={styles.containerGridAll} direction="row-reverse" >
				<Grid item xs={12} md={6} xl={5} className={`${styles.containerImgRight}`}>
					<img src={packagesImg1} alt="img" className={styles.right} />
				</Grid>
				<Grid
					item
					xs={12}
					md={6} xl={5}
					className={`${styles.containerData} ${styles.left} `}

				>
					<div data-aos="fade-up">
						<Grid item xs={12} className={`${styles.Header}`}>
							<p className={styles.blue}>{getLocale("Freestyle Pass Package")}</p>
						</Grid>
						<Grid item xs={12} className={`${styles.subTitle}`}>
							<p>
								{getLocale("Coming on 1st of July")}
							</p>
						</Grid>
						<Grid item xs={12} className={`${styles.description}`}>
							<p className={styles.black}>
								{getLocale("Freestyle Pass Package Content")}
							</p>
						</Grid>
						<Grid container item xs={12} className={`${styles.PackagePerPerson}`} alignItems="center" >
							<Grid item xs={12} md={6} className={`${styles.Header}`}>

								<Registration />
							</Grid>


						</Grid>
					</div>
				</Grid>
			</Grid>


		</Container>
	);
}
export default FreeStylePackage;

import Popup from "../../../General/popup/popup";
import React, { useState,useEffect } from "react";
import { Grid } from "@material-ui/core";
import useLocale from "../../../../lang/useLocale";
import styles from './registeration.module.scss';
import img from '../../../../img/freeStyleNightAccessPopUp.png';
import { useSelector } from "react-redux";
import axios from "axios";

const Registration =(props)=>{
    const { getLocale } = useLocale();
    const lang = useSelector((state) => state.lang);
    const [valueCheckbox,setValueCheckbox]=useState(true);
    // const [error, setError] = useState(false);
    const [valid,setValid]=useState(false);
    // const [submittingForm, setSubmittingForm] = useState(false);
    const [nameError,setNameError]=useState({name:"",})
    const [phoneError,setPhoneError]=useState({phone:"",})
    const [emailError,setEmailError]=useState({email:"",})
    const [valuef,setValueF]=useState("0");
    const [submitState,setsubmit]=useState(false);
    const [data,setData]=useState({
        name:"",
        phone:"",
        email:"",
        member:valuef,
    });
    // console.log(valuef);
useEffect(()=>{
if(valueCheckbox){
    setValueF("1")
// setData({...data, member:"1"})
}
else{
    setValueF("0")
    // setData({...data, member:"0"})
}
},[valueCheckbox,valuef])
useEffect(()=>{
    if (submitState && data ) {
   formValidation();}
          // eslint-disable-next-line react-hooks/exhaustive-deps
                      },[submitState,data])
    const formValidation=()=>{
        setValid(true);
    if (!data['name']) {
        setValid(false);
     setNameError({
         name:"this field is required"
     })
    }
    else{
        setNameError({
            name:""
        })
    }
    if (!data['phone']) {
        setValid(false);
        setPhoneError({
            phone:"this field is required"
        })
        }
        else{
            setPhoneError({
                phone:""
            })
        }
        if (!data['email']) {
            setValid(false);
            setEmailError({
                email:"this field is required"
            })
            }
            else{
                setEmailError({
                    email:""
                })
            }
}
const submit=(e)=>{
    e.preventDefault();
    // console.log(data)
    setsubmit(true);
  formValidation();
 if(valid){
sendForm();
 }
}
const sendForm=()=>{
    // console.log(data)
    axios.post("https://ski-back.tacverse.com/api/profissionals",data)
    .then((response)=>{
                // console.log(response)
                    // console.log(data)
                // setSubmittingForm(true);
                // clearTimeOut=setTimeout(()=>setMessage({message:`${getLocale("Thank you!")}`}))
            })
            .catch((error)=>{
                // console.log(error)
                // setError(true);
            })
}
    
          const contactFormChange=(e)=>{
            if(e.target.name === 'member'){
                setValueCheckbox(!valueCheckbox)
                // console.log(valueCheckbox)
            
                      }
              const{name,value}=e.target;
          setData({...data,[name]:value})
          
      

                }
    return(
        <div className={styles.registerationPackageContainer}>
<Popup
								popupTitle=""
								popupTriggerTitle={getLocale("Register Now")}
								popupTriggerClass={styles.registerationBtn}
							>
                                
								<div id="popUpRegisterationProfessionalPackage">
									<Grid container>
									<Grid item  md={6} xs={12} >
                                        <div className='bg'>
										<img  src={img} alt="skiClup"/>
                                        </div>
										</Grid>
										<Grid item md={6} xs={12} className="choo" style={{background:"#fff",borderBottomRightRadius:"5px",borderTopRightRadius:"5px",paddingTop: "50px"}}>
											<div className="popupRegisterationProfessionalPackageContentContainer" >
										<p className="subTitle">{getLocale('REGISTRATION')}</p>
<h3 className="title">{getLocale('freestyle night')}</h3>
<form onSubmit={(e)=>submit(e)} className={`${lang === "en" ? "LEFT" : "RTL"}`}>
                                    <Grid item xs={10}>
                                        <input
                                        	onChange={contactFormChange}
                                            name="name"
                                            type="text"
                                            placeholder={`${lang === "en" ? "Name" : "الأسم"}`}
                                        />
                                        {nameError.name &&
                                    (<div className="error">{nameError.name}</div>)}
                                    </Grid>
                                    <Grid item xs={10} >
                                        <input
                                        onChange={contactFormChange}
                                            name="email"
                                            type="email"
                                            placeholder={`${lang === "en" ? "Email" : "البريد الألكتروني"}`}
                                        />
                                        {emailError.email &&
                                    (<div className="error">{emailError.email}</div>)}
                                    </Grid>
                                    <Grid item xs={10} >
                                        <input
                                        onChange={contactFormChange}
                                            name="phone"
                                            type="text"
                                            placeholder={`${lang === "en" ? "Phone Number" : "رقم الهاتف"}`}
                                        />
                                        {phoneError.phone &&
                                    (<div className="error">{phoneError.phone}</div>)}
                                    </Grid>
                                    <Grid item xs={10}>
                                        <input
                                        onChange={contactFormChange}
                                            name="facebook"
                                            type="text"
                                            placeholder={`${lang === "en" ? "Facebook Profile Link" : "Facebook رابط الملف الشخصي على"}`}
                                        />
                                    </Grid>
                            <Grid item xs={10} style={{marginTop:"14px"}}>
                <div className="inputContainer">
                <p className='note'>
                    <input
                    name="member"
                    onChange={contactFormChange}
                    // onChange={()=>{setValueCheckbox(!valueCheckbox)}}
                type="checkbox"  value={valuef} /> 
               <span className='labelCheckbox'> {getLocale('I am a member of SKI School')}</span>
                </p>
               
                </div>
                </Grid>
                                    <Grid item xs={8} >
                                        <button className="register" type="submit">{`${lang === "en" ? "Register Now" : "سجل الآن"
                                            }`}</button>
                                    </Grid>

                            </form>
</div>
										</Grid>
									   
									</Grid>
								</div>
							  
							</Popup>
                            </div>
    )
}
export {Registration};

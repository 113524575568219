import React, { useEffect, useRef, useState } from "react";
import styles from "./skiSchool.module.scss";
import Banner from "../General/headerPanel/headerPanel";
import school from "../../img/skiSchoolnew.jpg";
import SocialSection from "../General/social-section/social-section";
// import OurAttractions from '../General/Our-Attractions/Our-Attractions';
import HeaderText from "./subComponents/skiSchoolHeaderText/skiSchoolHeaderText";
// import AlpineGrid from "../offers/subOffers/GridPackages/AlpineGrid";
import School from "./subComponents/school";
import Video from "../../components/General/videoBasic/video";
import MeetTrainers from "./subComponents/meetTrainers";
import LessonTutorial from "./subComponents/lessonTutorial";
import placeholder from "../../img/placeholder-skischool.jpg";
import alpinePrivateLesson from "../../img/alpine-private-lesson.jpg";
import alpineGroupLessonsOne from "../../img/alpine-group-lessons-one.jpg";
import alpineGroupLessonsTwo from "../../img/alpine-group-lessons-two.jpg";
import useLocale from "../../lang/useLocale";
import PackageCard from "../General/packageCard/packageCard";
import FromZeroToHero from "./subComponents/fromZeroToHero/fromZeroToHero";
import { useSelector } from "react-redux";
import packagesImg1 from "../../img/freestyle-pass-package.png";
import packagesImg2 from "../../img/slope-pass-packages.jpg";
import { SkiClubProgram } from "./subComponents/skiClubProgram/skiClubProgram";
import { Helmet } from "react-helmet";
// import Slider from "react-slick";
import Slider from "../General/swiper/swiper";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import { Grid } from "@material-ui/core";
import AOS from "aos";
import "aos/dist/aos.css";

export default function SkiSchool() {
  const schoolRef = useRef();
  const mySwiper = useRef();
  const mySwiper2 = useRef();

  const { getLocale } = useLocale();
  const [clicked, setClicked] = useState(false);
  const lang = useSelector((state) => state.lang);
  const chooseYourLesson = () => {
    setClicked(true);
    schoolRef.current.getBoundingClientRect();
    console.log(schoolRef.current.getBoundingClientRect());
  };
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  useEffect(() => {
    if (clicked) {
      schoolRef.current.scrollIntoView({
        behavior: "smooth",
      });
      setClicked(false);
    }
    // console.log(clicked)
  }, [clicked]);

  const settings = {
    arrows: true,
    bullets: false,
    loop: false,
    spaceBetween: 10,
    breakpoints: {
      640: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 1,
      },
      1025: {
        slidesPerView: 2.5,
      },
    },
  };
  return (
    <div className={styles.skiSchool}>
      <Helmet>
        <meta
          name="description"
          content={getLocale(
            "Join our Ski School for skiing or snowboarding lessons at Ski Egypt with our excellent professional instructors. Visit ski Egypt now for more details & enjoy ski lesson."
          )}
        />
        <title>
          {getLocale(
            "Ski School | Enjoy Ski Lesson & Snowboard in Egypt | SKI Egypt"
          )}
        </title>
      </Helmet>
      <Banner
        panal={school}
        alt="ski egypt school"
        containerCard
        title={getLocale("skischool")}
        description={getLocale("skischoolexert")}
      />
      <div data-aos="fade-up">
        <Video
          title={getLocale("WELCOME TO SKI SCHOOL")}
          img={placeholder}
          alt="التزحلق على الجليد"
          videoUrl="https://www.youtube.com/embed/tPz744gFQqw"
        />
      </div>
      <School refI={schoolRef} />
      <SkiClubProgram click={chooseYourLesson} />
      <MeetTrainers />
      <LessonTutorial />
      <FromZeroToHero />
      <div className={styles.alpineWrap}>
        <div>
          <div data-aos="fade-up">
            {/* <Container> */}
            <Grid container justifyContent="flex-end">
              <Grid item md={3} xs={12}>
                <HeaderText
                  title={getLocale("PACKAGES")}
                  subtitle={getLocale("Alpine Packages")}
                  description={
                    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum."
                  }
                />
              </Grid>
              <Grid item md={7} xs={12} className={styles.alpineSlider}>
                <Slider
                  key={`${lang}-swiper`}
                  settings={settings}
                  mySwiper={mySwiper}
                >
                  <div className={`swiper-slide`}>
                    <PackageCard
                      img={alpinePrivateLesson}
                      alt="تزلج على الجليد"
                      title={getLocale("Alpine Private Lessons")}
                      list={[
                        `${getLocale("5 Private lessons")}`,
                        `${getLocale("1 Complimentary private lesson")}`,
                        `${getLocale("1 Complimentary slope day pass")}`,
                        `${getLocale("1 complimentary hot chocolate")}`,
                      ]}
                      price="3750"
                      validity="6"
                      contact={getLocale("BOOK NOW")}
                      link={`https://booking.skiegy.com/${lang}/ski-school/booking/alpine-private`}
                    />
                  </div>
                  <div className={`swiper-slide`}>
                    <PackageCard
                      img={alpineGroupLessonsOne}
                      alt="مدينة الثلج"
                      title={getLocale("Alpine Group Lessons (3-6 yrs old)")}
                      list={[
                        `${getLocale("5 Group lessons")}`,
                        `${getLocale("1 Complimentary group lesson")}`,
                        `${getLocale("1 complimentary hot chocolate")}`,
                      ]}
                      price="1500"
                      validity="6"
                      contact={getLocale("BOOK NOW")}
                      link={`https://booking.skiegy.com/${lang}/ski-school/booking/alpine-child`}
                    />
                  </div>
                  <div className={`swiper-slide`}>
                    <PackageCard
                      img={alpineGroupLessonsTwo}
                      alt="تزحلق على الجليد"
                      title={getLocale("Alpine Group Lessons (7+ yrs old)")}
                      list={[
                        `${getLocale("5 Group lessons")}`,
                        `${getLocale("1 Complimentary group lesson")}`,
                        `${getLocale("1 complimentary hot chocolate")}`,
                      ]}
                      price="1500"
                      validity="6"
                      contact={getLocale("BOOK NOW")}
                      link={`https://booking.skiegy.com/${lang}/ski-school/booking/alpine-child-adult`}
                    />
                  </div>
                </Slider>
              </Grid>
            </Grid>
            {/* </Container> */}
            {/* <Container> */}
            <Grid container justifyContent="flex-end">
              <Grid item md={3} xs={12}>
                <HeaderText
                  title={getLocale("PACKAGES")}
                  subtitle={getLocale("PROFESSIONALS PACKAGES")}
                  description={
                    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum."
                  }
                />
              </Grid>
              <Grid item md={7} xs={12} className={styles.alpineSlider}>
                <Slider
                  key={`${lang}-swiper2`}
                  settings={settings}
                  mySwiper={mySwiper2}
                >
                  <div className={`swiper-slide`}>
                    <PackageCard
                      img={packagesImg2}
                      title={getLocale("Slope Pass Package")}
                      paragraph={getLocale("Slope Pass Package Content")}
                      price="300"
                      contact={getLocale("BOOK NOW")}
                      link={`https://booking.skiegy.com/${lang}/slope/booking/full-day-pass`}
                    />
                  </div>
                  <div className={`swiper-slide`}>
                    <PackageCard
                      img={packagesImg1}
                      title={getLocale("Freestyle Pass Package")}
                      paragraph={getLocale("Freestyle Pass Package Content")}
                      price="300"
                      contact={getLocale("CALL US")}
                      link="tel:16002"
                    />
                  </div>
                  <div>
                    {/* <PackageCard img={packagesImg2} title={getLocale("Slope Pass Package")} paragraph={getLocale("Slope Pass Package Content")} price="300" contact={getLocale("BOOK NOW")} link={`https://booking.skiegy.com/${lang}/slope/booking/full-day-pass`} /> */}
                  </div>
                </Slider>
              </Grid>
            </Grid>
            {/* </Container> */}
          </div>
        </div>
      </div>
      <SocialSection />
      {/* <OurAttractions /> */}
    </div>
  );
}

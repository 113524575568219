import {FETCH_DATA_IG} from './shared'

function fetchData(data) {
    return {
        type: FETCH_DATA_IG,
        data: data
    }
}

export function handleigFetchData(data) {
    return dispatch => {
        dispatch(fetchData(data))
    }
}
import React, { useState, useEffect } from "react";
import styles from "../home.module.scss";
import snow from "../../../img/img5.png";
import { Container, Grid } from "@material-ui/core";
import sking from "../../../img/OFFER-img-1.png";
import boarding from "../../../img/img4.png";
import penguins from "../../../img/penguins.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import useLocale from "../../../lang/useLocale";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Offers() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  const lang = useSelector((state) => state.lang);
  const { getLocale } = useLocale();
  const [readMoreOne, setReadMoreOne] = useState("exert");
  const [readMoreTwo, setReadMoreTwo] = useState("exert");
  const [readMoreThree, setReadMoreThree] = useState("exert");
  const [readMoreFour, setReadMoreFour] = useState("exert");

  const readMoreHandlerSelfOne = () => {
    if (readMoreOne === "exert seemore") {
      setReadMoreOne("exert");
    } else {
      setReadMoreOne("exert seemore");
    }
  };
  const readMoreHandler = () => {
    if (
      readMoreOne === "exert seemore" ||
      readMoreTwo === "exert seemore" ||
      readMoreThree === "exert seemore" ||
      readMoreFour === "exert seemore"
    ) {
      setReadMoreOne("exert");
      setReadMoreTwo("exert");
      setReadMoreThree("exert");
      setReadMoreFour("exert");
    } else {
      setReadMoreOne("exert seemore");
      setReadMoreTwo("exert seemore");
      setReadMoreThree("exert seemore");
      setReadMoreFour("exert seemore");
    }
    console.log(readMoreOne);
  };
  const readMoreHandlerSelfTwo = () => {
    if (readMoreTwo === "exert seemore") {
      setReadMoreTwo("exert");
    } else {
      setReadMoreTwo("exert seemore");
    }
    console.log(readMoreTwo);
  };
  const readMoreHandlerSelfThree = () => {
    if (readMoreThree === "exert seemore") {
      setReadMoreThree("exert");
    } else {
      setReadMoreThree("exert seemore");
    }
    console.log(readMoreThree);
  };
  const readMoreHandlerSelfFour = () => {
    if (readMoreFour === "exert seemore") {
      setReadMoreFour("exert");
    } else {
      setReadMoreFour("exert seemore");
    }
    console.log(readMoreFour);
  };
  return (
    <Container className={styles.offers} maxWidth="xl">
      <div data-aos="fade-up">
        <Grid
          container
          spacing={1}
          className={
            (`${styles.offersWrap}`, `${lang === "ar" && `${styles.reverse}`}`)
          }
        >
          <Grid item xs={11}>
            <h6>{getLocale("skiegypt")}</h6>
            <h2>{getLocale("whatdooffer")}</h2>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <div className={`${styles.slide}`}>
              <div>
                <Link
                  className={styles.cardWrapperLink}
                  to={"/" + lang + "/prices"}
                >
                  <div className={styles.cardImgWrap}>
                    <img src={snow} alt="snow" className={styles.sking} />
                    <div className={styles.cardWrapper}>
                      <h4 className={styles.title}>{getLocale("SNOW PARK")}</h4>
                      <p className={`${styles.excerpt} ${styles.one}`}>
                        {getLocale(`SNOW PARK ${readMoreOne}`)}
                      </p>
                    </div>
                  </div>
                </Link>
                <div>
                  {/* <Link className={styles.book} to={"/" + lang + "/prices"} rel='canonical'>
									{getLocale("learnmore")}
								</Link>  */}
                  <button
                    onClick={readMoreHandlerSelfOne}
                    className={styles.more}
                  >
                    READ MORE
                  </button>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className={`${styles.slide}`}>
              <div>
                <Link
                  className={styles.cardWrapperLink}
                  to={"/" + lang + "/skischool"}
                >
                  <div className={styles.cardImgWrap}>
                    <img src={sking} alt="sking" className={styles.sking} />
                    <div className={styles.cardWrapper}>
                      <h4 className={styles.title}>{getLocale("SKIING")}</h4>
                      <p className={styles.excerpt}>
                        {getLocale(`SKIING${readMoreTwo}`)}
                      </p>
                    </div>
                  </div>
                </Link>
                <div>
                  {/* <Link className={styles.book} to={"/" + lang + "/skischool"}>
									{getLocale("learnmore")}
								</Link> */}
                  <button
                    onClick={readMoreHandlerSelfTwo}
                    className={styles.more}
                  >
                    READ MORE
                  </button>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className={`${styles.slide}`}>
              <div>
                <Link
                  className={styles.cardWrapperLink}
                  to={"/" + lang + "/skischool"}
                >
                  <div className={styles.cardImgWrap}>
                    <img
                      src={boarding}
                      alt="boarding"
                      className={styles.sking}
                    />
                    <div className={styles.cardWrapper}>
                      <h4 className={styles.title}>
                        {getLocale("SNOWBOARDING")}
                      </h4>
                      <p className={styles.excerpt}>
                        {getLocale(`SNOWBOARDING${readMoreThree}`)}
                      </p>
                    </div>
                  </div>
                </Link>
                <div>
                  {/* <Link className={styles.book} to={"/" + lang + "/skischool"}>
									{getLocale("learnmore")}
								</Link> */}
                  <button
                    onClick={readMoreHandlerSelfThree}
                    className={styles.more}
                  >
                    READ MORE
                  </button>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className={`${styles.slide}`}>
              <div>
                <Link
                  className={styles.cardWrapperLink}
                  to={"/" + lang + "/penguins"}
                >
                  <div className={styles.cardImgWrap}>
                    <img src={penguins} alt="penguins" />
                    <div className={styles.cardWrapper}>
                      <h4 className={styles.title}>{getLocale("PENGUINS")}</h4>
                      <p className={styles.excerpt}>
                        {getLocale(`PENGUINS${readMoreFour}`)}
                      </p>
                    </div>
                  </div>
                </Link>
                <div>
                  {/* <Link className={styles.book} to={"/" + lang + "/skischool"}>
									{getLocale("learnmore")}
								</Link> */}
                  <button
                    onClick={readMoreHandlerSelfFour}
                    className={styles.more}
                  >
                    READ MORE
                  </button>
                </div>
                {/* <div>
								<Link className={styles.book} to={"/" + lang + "/penguins"}>
									{getLocale("learnmore")}
								</Link>
							</div> */}
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div onClick={readMoreHandler} className={styles.readMore}>
              {getLocale("read more")}
            </div>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

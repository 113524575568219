import { combineReducers } from 'redux'
import lang from './lang'
import size from './size'
import fb from './fb'
import ig from './ig'

const rootReducer = combineReducers({
    lang,
    size,
    fb,
    ig
})

export default rootReducer
import React from "react";
import HeaderPanel from "../General/headerPanel/headerPanel";
import panalAboutUs from "../../img/panal-about-us.jpg";
import SocialSection from "../General/social-section/social-section";
// import OurAttractions from '../General/Our-Attractions/Our-Attractions'
import Experience from "./SubComponents/Experience/Experience";
import Slider from "./SubComponents/Slider/Slider";
import { useSelector } from "react-redux";
import Packages from "../Home/SubComponents/packages";
import styles from "../Home/home.module.scss";
import Personalitytest from "./SubComponents/personalitytest/personalitytest";
function AboutUs() {
  const lang = useSelector((state) => state.lang);
  return (
    <div>
      <HeaderPanel
        containerCard
        panal={panalAboutUs}
        subTitle={lang === "en" ? "KNOW US" : "إعرفنا"}
        title={lang === "en" ? "EXPLORE SKI EGYPT" : "اكتشف سكي مصر"}
        description={
          lang === "en"
            ? "At Africa’s first indoor Ski resort and Snow Park, you can enjoy the spectacular 7,000 tons of real snow at below zero degrees. Get an unforgettable experience for everyone, with a range of unmatchable activities; enjoy our Snow Park with all its different rides, learn to Ski or Snowboard on our Slope, and meet our Snow Penguins through a special encounter or the Penguins March."
            : "في أول منتجع تزلج ومتنزه ثلجي داخلي في أفريقيا، يمكنك الاستمتاع بـ 7000 طن من الثلج الحقيقيي المذهل في درجات حرارة تحت الصفر. تجربة لا تنسى للجميع، مع مجموعة من الأنشطة التي لا مثيل لها؛ استمتع بمنتزهنا الثلجي بكافة ألعابه المختلفة، وتعلم التزلج على الجليد على منحدرنا، والالتقاء بالبطاريق من خلال مسيرة البطاريق أو لقاء خاص."
        }
      />
      <Experience />

      <div className={`${styles.home} home`}>
        <Packages />
      </div>
      <Slider />
      <Personalitytest />
      <SocialSection />
      {/* <OurAttractions/> */}
    </div>
  );
}

export default AboutUs;

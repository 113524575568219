import React from 'react'
import {Container, Grid} from '@material-ui/core'
import styles from './HeaderText.module.scss'
function HeaderText(props) {
    return (
        <Container maxWidth='lg' className={`${styles.HeaderText}`}>
            <Grid container justify='center' alignItems='center' className={`${props.HappyHours ? 'happyHours' : null}`}>
                <Grid item xs={12} md={8}>
                    <p className={`${styles.subTitle}`}>{props.title}</p>
                </Grid>
                <Grid item xs={12} md={8}>
                    <p className={`${styles.Title}`}>{props.subtitle}</p>

                </Grid>
                <Grid item xs={12} md={8}>
                    <p className={`${styles.description}`}>{props.description}</p>
                </Grid>
            </Grid>
        </Container>
    )
}

export default HeaderText;

import React from "react";
import styles from "./packages.module.scss";
import Banner from "../General/headerPanel/headerPanel";
import packages from "../../img/packagesPanel.jpg";
import HeaderText from "../offers/subOffers/HeaderText/HeaderText";
// import AlpineGrid from "../offers/subOffers/GridPackages/AlpineGrid";  To skiSchool
// import MembershipGrid from "../offers/subOffers/GridPackages/MembershipGrid";
// import SnowMuch from "./subComponents/snowMuch";
import Comparison from "./subComponents/comparison";
import LittleSnow from "../offers/subOffers/littleSnow";
import useLocale from "../../lang/useLocale";
import SocialSection from "../General/social-section/social-section";
import {Helmet} from "react-helmet";

export default function Packages() {
  const { getLocale } = useLocale();
  return (
    <div className={styles.packages}>
        <Helmet>
    <meta
      name="description"
      content={getLocale("Visit now ski Egypt to know prices & packages for enjoying time with family and friend. Visit now!")}
    />
      <title>{getLocale("Visit Ski to Know Prices and Packages | SKI Egypt")}</title>
            </Helmet>
      <Banner
        panal={packages}
        alt="سكي مصر للاطفال"
        containerCard
        title={getLocale("PACKAGES")}
        description={getLocale("PACKAGESexert")}
      />
      <Comparison />
      {/* <SnowMuch /> */}
      <div className={styles.littleWrap}>
        <HeaderText
          title={getLocale("PACKAGES")}
          subtitle={getLocale("Little Snow")}
          description={getLocale("Little Snowexert")}
        />
        <LittleSnow />
      </div>
      {/* <div className={styles.alpineWrap}>   to ski school
        <HeaderText
          title={getLocale("PACKAGES")}
          subtitle={getLocale("Alpine Packages")}
          description={getLocale("Bundled Ski School lessons with a discount")}
        />
        <AlpineGrid />
      </div> */}
      {/* <div className={styles.membershipWrap}>
        <HeaderText
          title={getLocale("Memberships")}
          subtitle={getLocale("Ski Fans Membership")}
          description={getLocale("Memberships for professional skiiers")}
        />
        <MembershipGrid />
      </div> */}
      <SocialSection />
    </div>
  );
}

import React, { useEffect, useRef } from "react";
import styles from "../home.module.scss";
import Swiper from "../../General/swiper/swiper";
import { Container, Grid } from "@material-ui/core";
import packages1 from "../../../img/packages1.png";
import packages1Mob from "../../../img/package1-mob.png";
import packages2Mob from "../../../img/package2-mob.png";
import packages3Mob from "../../../img/package3-mob.png";
import packages2 from "../../../img/packages2.png";
import packages3 from "../../../img/packages3.png";
// import hotDeals from "../../../img/hotDeals.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import useLocale from "../../../lang/useLocale";

import AOS from "aos";
import "aos/dist/aos.css";
// import { handleSize } from "../../../redux/actions/size";
// import _ from "lodash";

export default function Packages() {
  const lang = useSelector((state) => state.lang);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  // const size = useWindowSize();
  // const dispatch = useDispatch();
  const { getLocale } = useLocale();
  const mySwiper = useRef();
  const firstSlide = useRef();
  const settings = {
    // loop: true,
    spaceBetween: 10,
    arrows: true,
    bullets: false,

    // autoplay: {
    // 	delay: 5000,
    // },
    autoplayDisableOnInteraction: true,
    breakpoints: {
      640: {
        slidesPerView: 1.5,
      },
      768: {
        slidesPerView: 1.5,
      },
      1024: {
        slidesPerView: 2,
      },
      1400: {
        slidesPerView: 2.5,
      },
    },
    slidesPerView: "auto",
    on: {
      slideChange: function () {
        handleChange();
      },
      reachEnd: function () {
        // console.log("end");
        // console.log(firstSlide.current.style.marginLeft="0")
        if (lang === "en") {
          firstSlide.current.style.marginLeft = "0";
        } else if (lang === "ar") {
          firstSlide.current.style.marginRight = "0";
        }
      },
    },
  };
  // function useWindowSize() {
  // 	function getSize() {
  // 	  return window.innerWidth;
  // 	}
  // 	useEffect(() => {
  // 	  window.addEventListener(
  // 		"resize",
  // 		_.debounce(() => {
  // 		  dispatch(handleSize(getSize()));
  // 		}, 1000)
  // 	  );
  // 	  return () => window.removeEventListener("resize",null);
  // 	}, []);
  //   }
  const sizeRedux = useSelector((state) => state.size);
  const handleChange = () => {
    // 	if (mySwiper.current.isEnd) {
    // 		console.log("end");
    // console.log(firstSlide.current.style.marginLeft="0")
    // 		  }
    if (mySwiper.current.isBeginning) {
      // console.log("start");
      // console.log(swiperContainer.current?.children[0].children[3].classList.contains("swiper-button-disabled"))
      // console.log(firstSlide.current.style.marginLeft="140px")
      if (lang === "en") {
        firstSlide.current.style.marginLeft = "140px";
        firstSlide.current.style.marginRight = "0px";
      } else if (lang === "ar") {
        firstSlide.current.style.marginRight = "140px";
        firstSlide.current.style.marginLeft = "0px";
      }
    }
  };
  useEffect(() => {
    if (lang === "ar" && firstSlide.current) {
      firstSlide.current.style.marginRight = "140px";
      firstSlide.current.style.marginLeft = "0px";
    } else if (lang === "en" && firstSlide.current) {
      firstSlide.current.style.marginLeft = "140px";
      firstSlide.current.style.marginRight = "0px";
    }
  }, [lang, firstSlide]);

  return (
    <div className={`${styles.packages} packages`}>
      <Container maxWidth="xl">
        <h6 className={styles.title}>{getLocale("skiegypt")}</h6>
        <h2 className={styles.subTitle}>{getLocale("bestexperiences")}</h2>
      </Container>
      <div data-aos="fade-up">
        <div className="packagesWrap">
          <Swiper
            key={`${lang === "en" ? "ltr" : "rtl"}-swiper}`}
            settings={settings}
            mySwiper={mySwiper}
          >
            <div
              ref={firstSlide}
              className={`${styles.packageSlide} swiper-slide`}
            >
              <div>
                <div className={styles.cardImgWrap}>
                  <img
                    src={sizeRedux >= 768 ? packages1 : packages1Mob}
                    alt="packages1"
                  />
                  <Grid
                    className={styles.cardImgContainer}
                    container
                    justify="space-between"
                    alignItems="flex-end"
                    direction={lang === "ar" ? "row-reverse" : "row"}
                  >
                    <Grid item xs={12} md={8}>
                      {/* <h4>SNOWBOARDING</h4> */}
                      <h2>{getLocale("snowclassic")}</h2>
                      <p>{getLocale("snowclassicexert")}</p>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <a
                        href={`https://booking.skiegy.com/${lang}/snow-park/booking/snow-classic`}
                        rel="noopener noreferrer"
                        target="_self"
                        className={styles.book}
                      >
                        {getLocale("book")}
                      </a>
                      <Link
                        to={{
                          pathname: `/${lang}/prices`,
                          state: { moveTo: "tickets" },
                        }}
                        className={styles.view}
                        rel="canonical"
                      >
                        {getLocale("viewdetails")}
                      </Link>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
            <div className={`${styles.packageSlide} swiper-slide`}>
              <div>
                <div className={styles.cardImgWrap}>
                  <img
                    src={sizeRedux >= 768 ? packages2 : packages2Mob}
                    alt="packages1"
                  />
                  <Grid
                    className={styles.cardImgContainer}
                    container
                    justify="space-between"
                    alignItems="flex-end"
                    direction={lang === "ar" ? "row-reverse" : "row"}
                  >
                    <Grid item xs={12} md={8}>
                      {/* <h4>SNOWBOARDING</h4> */}
                      <h2>{getLocale("snowplus")}</h2>
                      <p>{getLocale("snowplusexert")}</p>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <a
                        href={`https://booking.skiegy.com/${lang}/snow-park/booking/snow-plus`}
                        rel="noopener noreferrer"
                        target="_self"
                        className={styles.book}
                      >
                        {getLocale("book")}
                      </a>
                      <Link
                        to={{
                          pathname: `/${lang}/prices`,
                          state: { moveTo: "tickets" },
                        }}
                        className={styles.view}
                        rel="canonical"
                      >
                        {getLocale("viewdetails")}
                      </Link>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
            <div className={`${styles.packageSlide} swiper-slide`}>
              {/* <img className= {styles.hotOffers} src={hotDeals} alt='Hot Offers'/> */}
              <div>
                <div className={styles.cardImgWrap}>
                  <img
                    src={sizeRedux >= 768 ? packages3 : packages3Mob}
                    alt="packages1"
                  />
                  <Grid
                    className={styles.cardImgContainer}
                    container
                    justify="space-between"
                    alignItems="flex-end"
                    direction={lang === "ar" ? "row-reverse" : "row"}
                  >
                    <Grid item xs={12} md={8}>
                      <h2>{getLocale("meetpenguins")}</h2>
                      <p>{getLocale("meetpenguinsexert")}</p>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <a
                        href={`https://booking.skiegy.com/${lang}/snow-penguins/booking/peng-friend-encounter`}
                        target="_self"
                        rel="noopener noreferrer"
                        className={styles.book}
                      >
                        {getLocale("book")}
                      </a>
                      <Link
                        to={{
                          pathname: `/${lang}/penguins`,
                          state: { moveTo: "meetPenguins" },
                        }}
                        className={styles.view}
                      >
                        {getLocale("viewdetails")}
                      </Link>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
            <div className={`${styles.packageSlide} swiper-slide`}>
              <div>
                <div className={styles.cardImgWrap}>
                  <img
                    src={sizeRedux >= 768 ? packages2 : packages2Mob}
                    alt="packages1"
                  />
                  <Grid
                    className={styles.cardImgContainer}
                    container
                    justify="space-between"
                    alignItems="flex-end"
                    direction={lang === "ar" ? "row-reverse" : "row"}
                  >
                    <Grid item xs={12} md={8}>
                      {/* <h4>SNOWBOARDING</h4> */}
                      <h2>{getLocale("snowpremium")}</h2>
                      <p>{getLocale("snowpremiumexert")}</p>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <a
                        href={`https://booking.skiegy.com/${lang}/slope/booking/snow-premium`}
                        target="_self"
                        rel="noopener noreferrer"
                        className={styles.book}
                      >
                        {getLocale("book")}
                      </a>
                      <Link
                        to={{
                          pathname: `/${lang}/prices`,
                          state: { moveTo: "tickets" },
                        }}
                        className={styles.view}
                        rel="canonical"
                      >
                        {getLocale("viewdetails")}
                      </Link>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </Swiper>
        </div>
      </div>
    </div>
  );
}

import React, {useEffect} from "react";
import { Container,Grid } from "@material-ui/core";
import styles from "./professionalPackages.module.scss";
import packagesImg2 from "../../../img/slope-pass-packages.jpg";
import useLocale from "../../../lang/useLocale";
import { useSelector } from "react-redux";
import AOS from "aos";
import "aos/dist/aos.css";

function SlopePassPackage() {
	useEffect(() => {
        AOS.init({
            duration : 1000,
            once: true
        });
    }, []);
	const { getLocale } = useLocale();
    const lang = useSelector((state) => state.lang);

	return (
		<Container maxWidth={false} className={styles.professionalPackages}>

			<Grid container className={styles.containerGridAll} direction="row" justify="center">
				<Grid  item xs={12} md={6} xl={5} className={`${styles.containerImgRight}`}>
					<img src={packagesImg2} alt="img" className={styles.left} />
				</Grid>
				<Grid
					item
					xs={12}
					md={6} xl={5}
					className={`${styles.containerData} ${styles.right}`}

				>
					<div data-aos="fade-up">
					<Grid item xs={12} className={`${styles.Header}`}>
						<p>{getLocale("Slope Pass Package")}</p>
					</Grid>
					<Grid item xs={12} className={`${styles.description}`}>
						<p>
                        {getLocale("Slope Pass Package Content")}
						</p>
					</Grid>            
					<Grid container item xs={12} className={`${styles.PackagePerPerson}`} alignItems="center">
						<Grid item xs={12} md={6} className={`${styles.Header}`}>
                        <a
								href={`https://booking.skiegy.com/${lang}/slope/booking/full-day-pass`}
								target="_self"
								rel="noopener noreferrer"
							>
								{getLocale("BOOK NOW")}
							</a>
                        
                            </Grid>
                            <Grid item xs={12} md={6} className={`${styles.Header}`}>
							<p>
								300<span>/{getLocale("Per Person")} </span>
							</p>
                            </Grid>
					</Grid>
					</div>
				</Grid>
			</Grid>


           

		</Container>
	);
}
export default SlopePassPackage;

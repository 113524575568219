import abdelrahmanImg from '../../../img/AbdelRahman.jpg'
import AhmedAkramImg from '../../../img/AhmedAkram.jpg'
import AhmedMohsenImg from '../../../img/AhmedMohsen.jpg'
import AhmedRabeaImg from '../../../img/AhmedRabea.jpg'
import HeshamFathyImg from '../../../img/HeshamFathy.jpg'
import IslaMagdyImg from '../../../img/IslaMagdy.jpg'
import KareemLoutfyImg from '../../../img/KareemLoutfy.jpg'
import MahmoudAbdouImg from '../../../img/MahmoudAbdou.jpg'
import MostafaElZoghbyImg from '../../../img/MostafaElZoghby.jpg'
import MostafaEssamImg from '../../../img/MostafaEssam.jpg'
import SamahSabryImg from '../../../img/SamahSabry.jpg'
import ShaimaaAdelImg from '../../../img/ShaimaaAdel.jpg'
export const abdelrahman = abdelrahmanImg;
export const AhmedAkram = AhmedAkramImg;
export const AhmedMohsen = AhmedMohsenImg;
export const AhmedRabea = AhmedRabeaImg;
export const HeshamFathy = HeshamFathyImg;
export const IslaMagdy = IslaMagdyImg;
export const KareemLoutfy = KareemLoutfyImg;
export const MahmoudAbdou = MahmoudAbdouImg;
export const MostafaElZoghby = MostafaElZoghbyImg;
export const MostafaEssam = MostafaEssamImg;
export const SamahSabry = SamahSabryImg;
export const ShaimaaAdel = ShaimaaAdelImg;
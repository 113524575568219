import slopeImg from "../../../../img/slope.jpg";
import snowboardingImg from "../../../../img/snowboarding.jpg";
import schoolImg from "../../../../img/school.jpg";
import bumbyroadImg from "../../../../img/bumbyroad.jpg";
import snakerideImg from "../../../../img/snakeRideImg.jpg";
import polarexpressImg from "../../../../img/polarexpress.jpg";
import bobsledImg from "../../../../img/BobsledRide.jpg";
import zorbballImg from "../../../../img/zorbball.jpg";
import snowrocketImg from "../../../../img/snowrocket.jpg";
import snowplayareaImg from "../../../../img/snowplayarea.jpg";
import icedcafeImg from "../../../../img/icedcafe.jpg";
import penguinencounterImg from "../../../../img/penguinencounter.jpg";
import penguinmarchImg from "../../../../img/penguinmarch.jpg";
import snowproshopImg from "../../../../img/snowproshop.jpg";
import snowflakeImg from "../../../../img/snowflake.jpg";

export const slope = slopeImg;
export const snowboarding = snowboardingImg;
export const school = schoolImg;
export const bumbyroad = bumbyroadImg;
export const snakeride = snakerideImg;
export const polarexpress = polarexpressImg;
export const bobsled = bobsledImg;
export const zorbball = zorbballImg;
export const snowrocket = snowrocketImg;
export const snowplayarea = snowplayareaImg;
export const icedcafe = icedcafeImg;
export const penguinencounter = penguinencounterImg;
export const penguinmarch = penguinmarchImg;
export const snowproshop = snowproshopImg;
export const snowflake = snowflakeImg;

import React , {useState, Fragment} from 'react'
import styles from '../penguins.module.scss'
import {Container, Grid} from '@material-ui/core'
import videoImg from '../../../img/march.jpg'
import useLocale from '../../../lang/useLocale'

export default function Video () {
    const[placeHolder, setPlaceHolder] = useState(true)
    function handlePlaceHolder (){
        setPlaceHolder(!placeHolder)
    }
    const {getLocale} = useLocale()
    return(
        <div className={styles.video}>
            <Container maxWidth='lg'>
                <Grid container justify='center' alignItems='center' spacing={1}>
                    <Grid item xs={12} md={6} style={{position: "relative"}}>
                        {
                            <Fragment>
                                <img src= {videoImg} alt= 'مسيرة البطاريق في سكي' onClick= {handlePlaceHolder} className={styles.placeHolder}/>
                            </Fragment> 
                        }
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <h4>{getLocale('SKI')}</h4>
                        <h2>{getLocale('MARCH OF THE PENGUINS')}</h2>
                        <p>
                            {getLocale('MARCH OF THE PENGUINSexert')}                            
                        </p>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
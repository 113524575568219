import React,{useState} from "react";
import styles from "./packageCard.module.scss";
import useLocale from "../../../lang/useLocale";
import { Grid,Modal } from "@material-ui/core";
import close from "../../../img/whiteClose.png";

// import alpineImg1 from "../../../img/alpineGro2.jpg";

export default function PackageCard(props) {
	// const [bg,setBg]=useState(props.img);
    const { getLocale } = useLocale();
    // const lang = useSelector((state) => state.lang);
    const [open1, setOpen1] = useState(false);
    const handleOpen1 = () => {
		setOpen1(true);
	};
    const handleClose1 = () => {
		setOpen1(false);
	};

	return (
		<div className={styles.packageCard}>
<div className={styles.packageCardContainer}>
    <img alt={props.alt?props.alt:"package"} src={props.img}/>
    <div className={styles.overlay}>  </div>
        <div className={styles.detailsContainer}>
    <h3>{props.title}</h3>
    <a className={styles.book} href={props.link}> {props.contact}</a>
    </div>
	<button onClick={handleOpen1} className={styles.detailsView}>{getLocale("viewdetails")}</button>

  
</div>
<Modal open={open1} onClose={handleClose1}>
					<div className={styles.modal}>
						<img onClick={handleClose1} src={close} alt="img" className={styles.close} />
                        <Grid container className={`${styles.containerGridAll} `}>
				<Grid container item xs={12} md={6} className={`${styles.containerImgRight}`}  style={{backgroundImage:`url(${props.img})`}}>
					{/* <img src={props.img} alt="img" /> */}
				</Grid>
				<Grid
					container
					item
					xs={12}
					md={6}
					className={`${styles.containerData}`}
					justify="flex-start"
					alignItems="flex-start"
				>
					<Grid item xs={12} className={`${styles.Header}`}>
						<p className={styles.title}>{props.title}</p>
					</Grid>
					<Grid item xs={12} className={`${styles.description}`}>
						{props.list &&(
							<ul>
							{props.list?.map((item,index)=>{
                      return ( <li key={index}>-{item}</li>)
							})}
							{/* <li>{getLocale("5 Group lessons")}</li>
							<li>{getLocale("1 Complimentary group lesson")}</li>
							<li>{getLocale("1 complimentary hot chocolate")}</li> */}
						</ul>
						)}
						{props.paragraph && (
							<p>{props.paragraph}</p>
						)}
					</Grid>
					{/* <Grid container item xs={12} className={`${styles.PackagePerPerson}`}>
						
					</Grid> */}
					<Grid container item xs={12} className={`${styles.PackagePerPerson}`} alignItems='flex-end' justify="space-between">
						<Grid item xs={12} className={`${styles.Header}`}>
							{props.validity && (
								<p>
								{getLocale("Validity")} {props.validity} {getLocale("months")}
							</p>
							)}
							</Grid>
							<Grid item md={5} xs={12} className={`${styles.Header}`}>
							<a
								// href={`https://booking.skiegy.com/${lang}/ski-school/booking/alpine-child`}
								href={props.link}
								target="_self"
								rel="noopener noreferrer"
							>
								{props.contact}
							</a>
							</Grid>
							<Grid item md={7} xs={12} className={`${styles.Header}`} >
							<p className={styles.price}>{props.price}<span>/{getLocale("Per Person")} </span>
							</p>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
					</div>
				</Modal>
		</div>
	);
}

import React, { useState, Fragment, useEffect } from "react";
import styles from "../home.module.scss";
import { Container, Grid } from "@material-ui/core";
import videoImg from "../../../img/video.jpg";
import videoImgMob from "../../../img/video-mob.png";
import play from "../../../img/play.svg";
import useLocale from "../../../lang/useLocale";
// import _ from "lodash";
import { useSelector } from "react-redux";
// import { handleSize } from "../../../redux/actions/size";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Video() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  // const size = useWindowSize();
  //   const dispatch = useDispatch();
  const [placeHolder, setPlaceHolder] = useState(true);
  function handlePlaceHolder() {
    setPlaceHolder(!placeHolder);
  }
  const { getLocale } = useLocale();
  //   function useWindowSize() {
  //     function getSize() {
  //       return window.innerWidth;
  //     }
  //     useEffect(() => {
  //       window.addEventListener(
  //         "resize",
  //         _.debounce(() => {
  //           dispatch(handleSize(getSize()));
  //         }, 1000)
  //       );
  //       return () => window.removeEventListener("resize", null);
  //     }, []);
  //   }
  const sizeRedux = useSelector((state) => state.size);

  return (
    <div className={styles.video}>
      <div data-aos="fade-up">
        <Container maxWidth="xl">
          <Grid container justify="center" alignItems="center" spacing={1}>
            <Grid item md={12} lg={6} style={{ position: "relative" }}>
              <Grid container justify="flex-end">
                <Grid item lg={11} md={12}>
                  {placeHolder ? (
                    <Fragment>
                      <img
                        src={sizeRedux >= 768 ? videoImg : videoImgMob}
                        alt="video"
                        onClick={handlePlaceHolder}
                        className={styles.placeHolder}
                      />
                      <img
                        src={play}
                        alt="play"
                        className={styles.play}
                        onClick={handlePlaceHolder}
                      />
                    </Fragment>
                  ) : (
                    <iframe
                      title="video"
                      width="100%"
                      height="376"
                      src="https://www.youtube.com/embed/eFpIKD0aJlw"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} lg={6}>
              <Grid container justify="flex-start">
                <Grid item md={12} lg={11}>
                  <h4>{getLocale("aboutski")}</h4>
                  <h2>{getLocale("gettoknow")}</h2>
                  <p>
                    {getLocale("gettoknow1")}
                    <br />
                    <br />
                    {getLocale("gettoknow2")}
                    <br />
                    <br />
                    {getLocale("gettoknow3")}
                    <br />
                    <br />
                    {getLocale("gettoknow4")}
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}

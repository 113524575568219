import React, { useEffect } from "react";
import styles from "./fromZeroToHero.module.scss";
import beginnerlevel from "../../../../img/beginnerlevel.png";
import intermediatelevel from "../../../../img/Intermediatelevel.png";
import advancedlevel from "../../../../img/advancedlevel.png";
import camps from "../../../../img/camps.png";
import professionals from "../../../../img/professionals.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { Grid } from "@material-ui/core";

function FromZeroToHero() {
  useEffect(() => {
    AOS.init({
      // duration : 5000
      once: true,
    });
  }, []);
  return (
    <div className={`${styles.mainContainer}`}>
      <div className={`${styles.secondtimeline}`}>
        <div className={`${styles.title__container} ${styles.title}`}>
          <div className={`${styles.content}`}>
            <h2 className={styles.header}>
              Ski school
              <br />{" "}
              <span id={`${styles.title__text}`}>
                from{" "}
                <span id={`${styles.bold}`}>
                  <span id={`${styles.number}`}>0</span> to hero
                </span>
              </span>
            </h2>
          </div>
        </div>
        <div className={`${styles.timeline}`}>
          <div className={`${styles.right}`}>
            <div className={`${styles.content}`}>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item md={6} xs={11}>
                  <div data-aos="fade-right">
                    <h2>
                      Beginner
                      <br /> Level
                    </h2>
                    <p>
                      Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad
                      nec admodum perfecto mnesarchum, vim ea mazim fierent
                      detracto. Ea quis iuvaret expetendis his, te elit voluptua
                      dignissim per, habeo iusto primis ea eam.
                    </p>
                    <button className={`${styles.content__button}`}>
                      Explore
                    </button>
                  </div>
                </Grid>
                <Grid item md={6} xs={11}>
                  <div data-aos="fade-left">
                    <img src={beginnerlevel} alt=""></img>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className={`${styles.right}`}>
            <div className={`${styles.content}`}>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item md={6} xs={11}>
                  <div data-aos="fade-right">
                    <img src={intermediatelevel} alt=""></img>
                  </div>
                </Grid>
                <Grid item md={6} xs={11}>
                  <div data-aos="fade-left">
                    <h2>
                      Intermediate
                      <br /> Level
                    </h2>
                    <p>
                      Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad
                      nec admodum perfecto mnesarchum, vim ea mazim fierent
                      detracto. Ea quis iuvaret expetendis his, te elit voluptua
                      dignissim per, habeo iusto primis ea eam.
                    </p>
                    <button className={`${styles.content__button}`}>
                      Explore
                    </button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>

          <div className={`${styles.right}`}>
            <div className={`${styles.content}`}>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item md={6} xs={11}>
                  <div data-aos="fade-right">
                    <h2>
                      Advanced
                      <br /> Level
                    </h2>
                    <p>
                      Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad
                      nec admodum perfecto mnesarchum, vim ea mazim fierent
                      detracto. Ea quis iuvaret expetendis his, te elit voluptua
                      dignissim per, habeo iusto primis ea eam.
                    </p>
                    <button className={`${styles.content__button}`}>
                      Explore
                    </button>
                  </div>
                </Grid>
                <Grid item md={6} xs={11}>
                  <div data-aos="fade-left">
                    <img src={advancedlevel} alt=""></img>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className={`${styles.right}`}>
            <div className={`${styles.content}`}>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item md={6} xs={11}>
                  <div data-aos="fade-right">
                    <img src={camps} alt=""></img>
                  </div>
                </Grid>
                <Grid item md={6} xs={11}>
                  <div data-aos="fade-left">
                    <h2>Camps</h2>
                    <p>
                      Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad
                      nec admodum perfecto mnesarchum, vim ea mazim fierent
                      detracto. Ea quis iuvaret expetendis his, te elit voluptua
                      dignissim per, habeo iusto primis ea eam.
                    </p>
                    <button className={`${styles.content__button}`}>
                      Register
                    </button>
                    <button className={`${styles.content__button__secondary}`}>
                      Explore
                    </button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className={`${styles.right}`}>
            <div className={`${styles.content}`}>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item md={6} xs={11}>
                  <div data-aos="fade-right">
                    <h2>Professionals</h2>
                    <p>
                      Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad
                      nec admodum perfecto mnesarchum, vim ea mazim fierent
                      detracto. Ea quis iuvaret expetendis his, te elit voluptua
                      dignissim per, habeo iusto primis ea eam.
                    </p>
                    <button className={`${styles.content__button}`}>
                      Explore
                    </button>
                  </div>
                </Grid>
                <Grid item md={6} xs={11}>
                  <div data-aos="fade-left">
                    <img src={professionals} alt=""></img>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <div className={`${styles.footer__container} ${styles.title}`}>
          <div className={`${styles.content}`}></div>
        </div>
      </div>
    </div>
  );
}

export default FromZeroToHero;

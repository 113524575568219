import React, {useEffect} from "react";
// import { useSelector } from "react-redux";
import styles from "./professionals.module.scss";
import VideoBasic from "../penguins/video/video";
// import school from "../../img/skiSchoolnew.jpg";
import SocialSection from "../General/social-section/social-section";
// import OurAttractions from '../General/Our-Attractions/Our-Attractions';
// import HeaderText from "../offers/subOffers/HeaderText/HeaderText";
import videoImg from "../../img/professionals.jpg";
import useLocale from "../../lang/useLocale";
import SlopePassPackage from "./professionalPackages/slopePassPackage";
import FreeStylePackage from "./professionalPackages/freeStylePackage";
// import CrystalPackages from "./crystalPackages/crystalPackages";
import { Grid, Container} from "@material-ui/core";
// import PackageCard from "../General/packageCard/packageCard";
import bg from "../../img/professionalsBg.jpg";
// import packagesImg2 from "../../img/slope-pass-packages.jpg";
import AOS from "aos";
import "aos/dist/aos.css";


export default function Professionals() {
	useEffect(() => {
        AOS.init({
            duration : 1000,
            once: true
        });
    }, []);
	const { getLocale } = useLocale();
	//  const lang = useSelector((state)=>state.lang);
	return (
		<div className={styles.professionals}>
		<VideoBasic title="PROFESSIONALS' JOURNEY" img={videoImg} videoUrl="https://www.youtube.com/embed/XXNtdlRxPF8" />
			 <Container className={styles.professionalPackages}>
			 <div data-aos="fade-up">
				<Grid container justify="center" alignItems="center">
					<Grid item xs={12}>
						<h3>{getLocale("Expert Packages")}</h3>
						<h2>{getLocale("PROFESSIONALS")}</h2>
						<p>{getLocale("professionalContent")}</p>
					</Grid>
				</Grid>
				</div>
				</Container>
        {/* <HeaderText
          title={getLocale("Expert Packages")}
          subtitle={getLocale("Professionals")}
          description={getLocale("professionalContent")}
        /> */}
		<SlopePassPackage/>
		{/* <Grid container spacing={1} justify="center">
			<Grid item md={6} xs={12}>
			<PackageCard img={packagesImg2} title={getLocale("Slope Pass Package")} paragraph={getLocale("Slope Pass Package Content")} price="300" contact={getLocale("BOOK NOW")} link={`https://booking.skiegy.com/${lang}/slope/booking/full-day-pass`}/>
			</Grid>
			<Grid item md={6} xs={12}>
			<PackageCard img={packagesImg1} title={getLocale("Freestyle Pass Package")} paragraph={getLocale("Freestyle Pass Package Content")} price="300" contact={getLocale("CALL US")} link="tel:16002"/>
			</Grid></Grid> */}
			<div data-aos="fade-up">
			<img src={bg} alt="professionals"/>
			<p className={styles.start}>{getLocale("Register Below For Our Next Competition")}</p>
			<p className={styles.date}>{getLocale("IN JULY")}</p>
			<FreeStylePackage/>
			</div>
			<div className={styles.bgColor}>
			<div data-aos="fade-up">
				<p>{getLocale("COMING SOON")}</p>
				<p>{getLocale("wait for our new Slope memberships")}</p>
				</div>
			</div>
        {/* <CrystalPackages/> */}
      {/* </div> */}
			<SocialSection />
			{/* <OurAttractions /> */}
		</div>
	);
}

import AhmedHussein from '../../../img/AhmedHussein.jpg'
import AwadOmar from '../../../img/AwadOmar.jpg'
import DanielleFarah from '../../../img/DanielleFarah.jpg'
import DiegoIgnacio from '../../../img/DiegoIgnacio.jpg'
import KarimMahmoud from '../../../img/KarimMahmoud.jpg'
import MagedMohamed from '../../../img/MagedMohamed.jpg'
import MarawanMounir from '../../../img/MarawanMounir.jpg'
import MostafaAbdel from '../../../img/MostafaAbdel.jpg'

export const AhmedHusseinImg = AhmedHussein
export const AwadOmarImg= AwadOmar
export const DanielleFarahImg= DanielleFarah
export const DiegoIgnacioImg= DiegoIgnacio
export const KarimMahmoudImg= KarimMahmoud
export const MagedMohamedImg= MagedMohamed 
export const MarawanMounirImg= MarawanMounir
export const MostafaAbdelImg= MostafaAbdel
import React, { useRef, useEffect, useState } from "react";
import styles from "../penguins.module.scss";
import Slider from "../../General/swiper/swiper";
import { Grid, Container } from "@material-ui/core";
import * as trainer from "./trainersimgs";
import useLocale from "../../../lang/useLocale";
import { useSelector } from "react-redux";

export default function MeetTrainers() {
  const { getLocale } = useLocale();
  const lang = useSelector((state) => state.lang);

  const mySwiper1 = useRef();
  const mySwiper2 = useRef();
  const settings1 = {
    arrows: true,
    bullets: false,
    loop: false,
    spaceBetween: 10,
    breakpoints: {
      640: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      1025: {
        slidesPerView: 2,
      },
    },
  };
  const [autoplay, setautoplay] = useState({
    delay: 10000,
  });
  useEffect(() => {
    if (lang) {
      mySwiper2.current.autoplay.stop();
      console.log(mySwiper2.current.autoplay);
      setautoplay({
        delay: 10000,
      });
    }
  }, [lang]);
  const settings2 = {
    arrows: false,
    bullets: true,
    loop: false,
    spaceBetween: 10,
    autoplay: autoplay,
    autoplayDisableOnInteraction: true,
    breakpoints: {
      640: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      1025: {
        slidesPerView: 4,
      },
    },
  };
  return (
    <div className={styles.meetTrainersWrap}>
      <Container>
        <div
          className={`${styles.ticketsPenguins} penguins ${styles.trainers}`}
        >
          <div className={styles.meet}>
            <h4>{getLocale("MEET THE PENGUINS TEAM")}</h4>
          </div>
          <div id="swiperPenguin" className={styles.swiperPenguin}>
            <Container maxWidth="md">
              <Slider settings={settings1} mySwiper={mySwiper1}>
                <div
                  className={`swiper-slide ${styles.penguinSlide} ${styles.foreign}`}
                >
                  <img src={trainer.DanielleFarahImg} alt="penguin" />
                  <div className={styles.wrap}>
                    <h5>{getLocale("Danielle Farah")}</h5>
                    <Grid container justify="space-between">
                      <Grid item xs={12} className={styles.sliderItem}>
                        <span>{getLocale("Penguin Curator")}</span>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div
                  className={`swiper-slide ${styles.penguinSlide} ${styles.foreign}`}
                >
                  <img src={trainer.DiegoIgnacioImg} alt="penguin" />
                  <div className={styles.wrap}>
                    <h5>{getLocale("Diego Ignacio Díaz")}</h5>
                    <Grid container justify="space-between">
                      <Grid item xs={12} className={styles.sliderItem}>
                        <span>{getLocale("Penguin Trainer")}</span>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Slider>
            </Container>
            <Slider
              key={`${lang}-swiper2`}
              settings={settings2}
              mySwiper={mySwiper2}
            >
              <div
                className={`swiper-slide ${styles.penguinSlide} ${styles.egyptian}`}
              >
                <img src={trainer.AhmedHusseinImg} alt="penguin" />
                <h5>{getLocale("Ahmed Hussein")}</h5>
                <Grid container justify="space-between">
                  <Grid item xs={12} className={styles.sliderItem}>
                    <span>{getLocale("Assistant Penguin Trainer")}</span>
                  </Grid>
                </Grid>
              </div>
              <div
                className={`swiper-slide ${styles.penguinSlide} ${styles.egyptian}`}
              >
                <img src={trainer.AwadOmarImg} alt="penguin" />
                <h5>{getLocale("Awad Omar")}</h5>
                <Grid container justify="space-between">
                  <Grid item xs={12} className={styles.sliderItem}>
                    <span>{getLocale("Assistant Penguin Trainer")}</span>
                  </Grid>
                </Grid>
              </div>
              <div
                className={`swiper-slide ${styles.penguinSlide} ${styles.egyptian}`}
              >
                <img src={trainer.KarimMahmoudImg} alt="penguin" />
                <h5>{getLocale("Karim Mahmoud")}</h5>
                <Grid container justify="space-between">
                  <Grid item xs={12} className={styles.sliderItem}>
                    <span>{getLocale("Assistant Penguin Trainer")}</span>
                  </Grid>
                </Grid>
              </div>
              <div
                className={`swiper-slide ${styles.penguinSlide} ${styles.egyptian}`}
              >
                <img src={trainer.MagedMohamedImg} alt="penguin" />
                <h5>{getLocale("Maged Mohamed")}</h5>
                <Grid container justify="space-between">
                  <Grid item xs={12} className={styles.sliderItem}>
                    <span>{getLocale("Assistant Penguin Trainer")}</span>
                  </Grid>
                </Grid>
              </div>
              {/* <div className={`swiper-slide ${styles.penguinSlide} ${styles.egyptian}`}>
                                <img src={trainer.MarawanMounirImg} alt='penguin'/>
                                <h5>{getLocale('Marawan Mounir')}</h5>
                                <Grid container justify='space-between'>
                                    <Grid item xs={12} className={styles.sliderItem}><span>{getLocale('Assistant Penguin Trainer')}</span></Grid>
                                </Grid>
                            </div> */}
              <div
                className={`swiper-slide ${styles.penguinSlide} ${styles.egyptian}`}
              >
                <img src={trainer.MostafaAbdelImg} alt="penguin" />
                <h5>{getLocale("Mostafa Abdel")}</h5>
                <Grid container justify="space-between">
                  <Grid item xs={12} className={styles.sliderItem}>
                    <span>{getLocale("Assistant Penguin Trainer")}</span>
                  </Grid>
                </Grid>
              </div>
            </Slider>
          </div>
        </div>
      </Container>
    </div>
  );
}

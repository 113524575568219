import React, { Fragment, useState, useEffect } from "react";
import styles from "./Experience.module.scss";
import { Container, Grid } from "@material-ui/core";
import videoImg from "../../../../img/img-placeholder.jpg";
import { useSelector } from "react-redux";
import useLocale from "../../../../lang/useLocale";
import AOS from "aos";
import "aos/dist/aos.css";

function Experience() {
  useEffect(() => {
    AOS.init({
       duration : 1000
      
    });
  }, []);
  const [placeHolder, setPlaceHolder] = useState(true);
  const lang = useSelector((state) => state.lang);
  const { getLocale } = useLocale();
  const size = useSelector((state) => state.size);
  const [readMore, setReadMore] = useState(false);
  const readMoreHandler = () => {
    setReadMore(!readMore);
  };
  function handlePlaceHolder() {
    setPlaceHolder(!placeHolder);
  }
  return (
    <Container maxWidth="lg" className={`${styles.containerExperience}`}>
       <div data-aos="fade-up">
      <Grid
        className={styles.flexContainer}
        container
        justify="center"
        alignItems="center"
        spacing={2}
        direction={lang === "en" ? "row" : "row-reverse"}
      >
        <Grid
          container
          item
          xs={12}
          md={6}
          className={`${styles.containerData}`}
          style={{ textAlign: lang === "en" ? "left" : "right" }}
        >
          <Grid
            item
            className={`${styles.containerHeader}`}
            xs={12}
            style={{ textAlign: lang === "en" ? "left" : "right" }}
          >
            <p>{getLocale("experiencelike")}</p>
          </Grid>
          <Grid item className={`${styles.containerDescription}`} xs={12}>
            {getLocale("experiencelike1")}
            {readMore ? null : "...."}
            <br />
            {size <= 900 ? (
              <>
                {readMore && getLocale("experiencelike2")}
                <div className={styles.readMoreBtn} onClick={readMoreHandler}>
                  {getLocale("read more")} &gt;
                </div>
              </>
            ) : (
              getLocale("experiencelike2")
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} className={`${styles.containerVideo}`}>
          {placeHolder ? (
            <Fragment>
              <img
                src={videoImg}
                alt="video"
                onClick={handlePlaceHolder}
                className={styles.placeHolder}
              />
            </Fragment>
          ) : (
            <iframe
              title="video"
              width="100%"
              height="376"
              src="https://www.youtube.com/embed/Tfc72QHpCEM"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          )}
        </Grid>
      </Grid>
      </div>
    </Container>
  );
}
export default Experience;

import React from 'react'
import {Container, Grid} from '@material-ui/core'
import styles from './GridPackages/GridPackages.module.scss'
import packagesImg from '../../../img/little.jpeg'
import useLocale from '../../../lang/useLocale'
import {useSelector} from 'react-redux'

function GridPackages() {
    const {getLocale} = useLocale()
    const lang = useSelector(state => state.lang)
    return (
        <Container maxWidth='lg'>
            <Grid container className={`${styles.containerGridAll}`} direction={lang === 'en' ? 'row' : 'row-reverse'}>
                <Grid container item xs={12} md={6} className={`${styles.containerImgRight}`}>
                    <img src={packagesImg} alt='اللعب على الجليد في سكي'/>
                </Grid>
                <Grid container item xs={12} md={6} className={`${styles.containerData}`} justify='flex-start' alignItems='flex-start'>
                    <Grid item xs={12} className={`${styles.Header}`}>
                        <p>
                            {getLocale('Package includes')}
                        </p>
                    </Grid>
                    <Grid item xs={12} className={`${styles.description}`}>
                        <p className={styles.listHeader}>{getLocale('littlesnowoptionheader')}</p>
                        <ul>
                            <li>{getLocale('littlesnowoption1')}</li>
                            <li>{getLocale('littlesnowoption2')}</li>
                            <li>{getLocale('littlesnowoption3')}</li>
                            <li>{getLocale('littlesnowoption4')}</li>
                        </ul>
                    </Grid>
                    <Grid container item  xs={12} className={`${styles.PackagePerPerson}`}>
                        <Grid item className={`${styles.Header}`}>
                            <p>
                                {getLocale('Booking')}<span>{getLocale('noonlinebooking')}</span>
                            </p>
                        </Grid>
                    </Grid>
                    <Grid container item  xs={12} className={`${styles.PackagePerPerson}`}>
                        <Grid item className={`${styles.Header}`}>
                            <p>
                                {getLocale('newpriceforkids')}
                            </p>
                        </Grid>
                        <Grid container item className={`${styles.description}`} alignItems='center'>
                            <Grid container alignItems='center'>
                                <Grid item xs={6} md={3}>
                                    <p>175 
                                        <span> / {getLocale('LE')} </span>
                                    </p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}
export default GridPackages
import React from "react";
import { Grid } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faYoutube,
  // faTiktok
} from "@fortawesome/free-brands-svg-icons";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./header.module.scss";
// import searchIcon from '../../img/search.svg'
// import snow from '../../img/snow.svg'
import { useDispatch, useSelector } from "react-redux";
import { handleLang } from "../../redux/actions/lang";
import snow from "../../img/flake-white.svg";
import british from "../../img/british.svg";
import egypt from "../../img/egypt.svg";

// import useLocale from '../../lang/useLocale'
// import { Link } from 'react-router-dom'
import CustomSelect from "../select/select";
function TopBar(props) {
  const browserLang = useSelector((state) => state.lang);
  const dispatch = useDispatch();
  let location = useLocation();
  let history = useHistory();
  const changeLang = (e) => {
    // setLang(e.currentTarget.dataset.value);
    dispatch(handleLang(e.currentTarget.dataset.value));
    document.cookie = `lang=${e.currentTarget.dataset.value}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
    let url =
      "/" + e.currentTarget.dataset.value + location.pathname.substring(3);
    history.push(url);
    // console.log(e.currentTarget.dataset.value)
  };
  // eslint-disable-next-line
  // const [lang, setLang] = useState("en");
  if (props.size > 1199) {
    return (
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={styles.topBar}
      >
        <Grid item xs={6} className={styles.socialIcons}>
          <ul>
            <li>
              <a
                href="https://www.facebook.com/skiegypt"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/ski.egypt/?hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCggXDtcjumJo_b_Rex5xnOg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faYoutube} />
              </a>
            </li>
            <li>
              <a
                href="https://www.tiktok.com/@ski.egypt?is_copy_url=1&is_from_webapp=v1"
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* <FontAwesomeIcon icon={faTiktok} /> */}
              </a>
            </li>
          </ul>
        </Grid>
        <Grid item xs={6}>
          <Grid container justify="flex-end" alignItems="center">
            <Grid item className={styles.snow}>
              <Grid
                container
                alignItems="center"
                direction="row"
                justify="center"
                className={styles.boxwrap}
              >
                <Grid item className={styles.time}>
                  {/* {props.time} */}
                  <img style={{ width: "31.92px" }} src={snow} alt="snow" />
                </Grid>
                <Grid item className={styles.degree}>
                  <span>{props.degree}</span>
                </Grid>
              </Grid>
            </Grid>

            <Grid item className={styles.topBarRight}></Grid>
            <Grid item>
              <CustomSelect
                name="lang"
                className={`${styles.langSelct} ${
                  browserLang === "ar" ? styles.arabic : styles.english
                }`}
                options={[
                  {
                    value: "en",
                    label: (
                      <>
                        <img alt="country" src={british} /> <span>ENGLISH</span>
                      </>
                    ),
                  },
                  {
                    value: "ar",
                    label: (
                      <>
                        <img alt="country" src={egypt} /> <span>العربية</span>
                      </>
                    ),
                  },
                ]}
                placeholder={browserLang === "ar" ? "العربية" : "ENGLISH"}
                changeHandler={changeLang}
                // soon
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
}

export default TopBar;

import React from 'react';
import styles from './tabsForGroupPackages.module.scss'
import { AppBar, Tabs, Tab } from '@material-ui/core';
import useLocale from "../../../../lang/useLocale";
import CorporateBenefits from '../corporateBenefits/corporateBenefits';
import corporateBenefitsOne from "../../../../img/corporatebenefitsone.png"
import corporateBenefitsTwo from "../../../../img/corporatebenefitstwo.png"
import schoolTripsOne from "../../../../img/school-trips-one.png"
import schoolTripsTwo from "../../../../img/school-trips-two.png"
import birthday from "../../../../img/Birthday.png"
import BirthdayParties from '../birthdayParties/birthdayParties';
import { Container } from "@material-ui/core";




export default function TabsForGroupPackages() {
    const { getLocale } = useLocale();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={styles.TabsForGroupPackagesContainer}>
            <AppBar position="static" className={`${styles.MuiAppBar}`} >
                <Container>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    centered
                    TabIndicatorProps={{
                        style: {
                            display: "none",
                        }
                    }}
                >

                    <Tab selected className={`${styles.MuiTab}`} label={getLocale("Corporate Benefits")} />
                    <Tab label={getLocale("School Trips")} />
                    {/* <Tab label={getLocale("Team Building")} /> */}
                    <Tab label={getLocale("Birthday Parties")} />
                    

                </Tabs>
                </Container>
            </AppBar>
            {value === 0 &&
                <React.Fragment>
                    <CorporateBenefits
                        imgOne={corporateBenefitsOne}
                        imgTwo={corporateBenefitsTwo}
                        title={getLocale("Corporate Benefits")}
                        paragraph={getLocale("CORPORATE PACKAGES CONTENT")}
                    />
                </React.Fragment>
            }
            {value === 1 &&
                <React.Fragment>
                    <CorporateBenefits
                        imgOne={schoolTripsOne}
                        imgTwo={schoolTripsTwo}
                        title={getLocale("SCHOOL TRIP PACKAGES")}
                        paragraph={getLocale("SCHOOL TRIP PACKAGES Content")}
                    />
                </React.Fragment>
            }
            {/* {value === 2 &&
                <React.Fragment>
                    <CorporateBenefits
                        imgOne={teamBuildingOne}
                        imgTwo={teamBuildingTwo}
                        title={getLocale("TEAM-BUILDING ACTIVITIEs")}
                        paragraph={getLocale("teamBuildingActivitiesContentTwo")}
                    />
                </React.Fragment>
            } */}
            {value === 2 &&
                <React.Fragment>
                    <BirthdayParties
                        img={birthday}
                        title={getLocale("Birthday Group Packages")}
                        paragraph={getLocale("BirthdayPartiesContent")}
                    />
                </React.Fragment>
            }
        </div>
    );
}

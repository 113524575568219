import React, { useEffect, useState } from "react";
import styles from "./contactUs.module.scss";
import Popup from "../../../General/popup/popup";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
// import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import useLocale from "../../../../lang/useLocale";
import axios from "axios";
import loaderImg from "../../../../img/loader.gif";
import MailchimpSubscribe from "react-mailchimp-subscribe";




export default function ContactUs(props) {
    let history = useHistory();
    const lang = useSelector((state) => state.lang);
    const { getLocale } = useLocale();
    // const { register, handleSubmit, errors } = useForm(); // initialize the hook
    const [loader, setLoader] = useState(false);
    const [submittingForm, setSubmittingForm] = useState(false);
    const [firstNameError,setFirstNameError]=useState({first_name:"",})
    const [lastNameError,setLastNameError]=useState({last_name:"",})
    const [phoneError,setPhoneError]=useState({phone:"",})
    const [emailError,setEmailError]=useState({email:"",})
    const [messageError,setMessageError]=useState({message:"",})
    const [submitState,setsubmit]=useState(false);

    const [data,setData]=useState({
        subject:`${props.subject}`,
        first_name:"",
        last_name:"",
        phone:"",
        email:"",
        message:"",
    });
    const [checked, setChecked]=useState(false);
    const [error, setError] = useState(false);
    const [valid,setValid]=useState(false);
    const formValidation=()=>{
        setValid(true);
    if (!data['first_name']) {
        setValid(false);
     setFirstNameError({
         first_name:"this field is required"
     })
    }
    else{
        setFirstNameError({
            first_name:""
        })
    }
   if (!data['last_name']) {
    setValid(false);
    setLastNameError({
        last_name:"this field is required"
    })
    }
    else{
        setLastNameError({
            last_name:""
        })
    }
    if (!data['phone']) {
        setValid(false);
        setPhoneError({
            phone:"this field is required"
        })
        }
        else{
            setPhoneError({
                phone:""
            })
        }
        if (!data['email']) {
            setValid(false);
            setEmailError({
                email:"this field is required"
            })
            }
            else{
                setEmailError({
                    email:""
                })
            }
            if (!data['message']) {
                setValid(false);
                setMessageError({
                    message:"this field is required"
                })
                }
                else{
                    setMessageError({
                        message:""
                    })
                }
                // console.log(valid)
}

    let hideTimeOut;

const submit=(e,subscribe)=>{
    setsubmit(true);
  e.preventDefault();
  formValidation();
 if(valid){
  if(checked){
    let emailInput = e.target.children[0].children[3].children[0].value;
    subscribe({ emailInput });
  }
sendForm();
 }
}
const sendForm=()=>{
    setLoader(true);
    axios.post('https://ski-back.tacverse.com/api/requestCall',data)
    .then((response)=>{
                setLoader(false);
                // console.log(response)
                setSubmittingForm(true);
                hideTimeOut=setTimeout(()=>hidePopUp(),3000)
                // clearTimeOut=setTimeout(()=>setMessage({message:`${getLocale("Thank you!")}`}))
            })
            .catch((error)=>{
                // console.log(error)
                setLoader(false);
                setError(true);
            })
}
        const hidePopUp=()=>{
            history.push("/");
            setSubmittingForm(false);
        }
        useEffect(()=>{
		return	clearTimeout(hideTimeOut)

					},)

                    useEffect(()=>{
                  if (submitState) {
                 formValidation();

                     }
                        // eslint-disable-next-line react-hooks/exhaustive-deps
                                    },[data,submitState])
                    const contactFormChange=(e)=>{
                        const{name,value}=e.target;
                    setData({...data,[name]:value})
                          }
    return (
        <div className={styles.contactUsContainer}>

            {/* <FontAwesomeIcon
                        style={{ cursor: "pointer" }}
                        icon={faPhone} /> */}

            <Popup
                popupTitle={`${lang === "en" ? "Contact Us" : "تواصل معنا"}`}
                popupTriggerTitle={`${lang === "en" ? "Request A Call" : "تواصل معنا"}`}
                popupTriggerClass={styles.btnContact}
            >
                <div id="contactPopup">
                    <p>{`${lang === "en" ? "Contact Us" : "تواصل معنا"}`}</p>
                    <MailchimpSubscribe url={process.env.REACT_APP_MAILCHIMP_URL}
                    render={({subscribe})=>(
                                      <form onSubmit={(e)=>submit(e,subscribe)} className={`${lang === "en" ? "LEFT" : "RTL"}`}>
                        <Grid container justify="space-between" className="form">
                            <Grid item xs={12} md={6}>
                                <input
                                    name="first_name"
                                    type="text"
                                    placeholder={`${lang === "en" ? "*First Name" : "الأسم الأول*"}`}
                                    value={data.first_name||''}
                                    onChange={contactFormChange}
                                />
                                    {firstNameError.first_name &&
                                    (<div className="error">{firstNameError.first_name}</div>)}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <input
                                    name="last_name"
                                    type="text"
                                    placeholder={`${lang === "en" ? "*Last Name" : "الأسم الأخير*"}`}
                                    onChange={contactFormChange}
                                    value={data.last_name||''}
                                />
                                    {lastNameError.last_name &&
                                    (<div className="error">{lastNameError.last_name}</div>)}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <input
                                    name="phone"
                                    type="text"
                                    placeholder={`${lang === "en" ? "*Phone Number" : "رقم الهاتف*"}`}
                                    onChange={contactFormChange}
                                    value={data.phone||''}
                                />
                                   {phoneError.phone &&
                                    (<div className="error">{phoneError.phone}</div>)}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <input
                                    name="email"
                                    type="email"
                                    placeholder={`${lang === "en" ? "*Email Address" : "البريد الألكتروني*"}`}
                                    onChange={contactFormChange}
                                    value={data.email||''}
                                />
                                    {emailError.email &&
                                    (<div className="error">{emailError.email}</div>)}
                            </Grid>
                            <Grid item xs={12}>
                                <textarea
                                    name="message" placeholder={`${lang === "en" ? "*Message" : "الرسالة*"}`}
                                    onChange={contactFormChange}
                                    value={data.message||''}
                                    />
                                     {messageError.message &&
                                    (<div className="error">{messageError.message}</div>)}
                            </Grid>
                            <Grid item xs={12} style={{textAlign:"left"}} >
                             <input type = "checkbox" id="checkBox" onChange={()=>setChecked(!checked)}/>
                             <label htmlFor="checkBox">Subscribe to our newsletter to stay updated with our latest news and offers</label>
                            </Grid>
                            
                        </Grid>
                        <Grid container justify="center">
                            <Grid item className="containerBtnSubmit">
                                <button className="submit" type="SUBMIT">{`${lang === "en" ? "Submit" : "ارسال"
                                    }`}</button>
                            </Grid>
                            {loader ? (
                                    <div className="loader">
                                   <img src={loaderImg} alt="loader" />
                                     </div>
                                                  ) : null}
                            { submittingForm &&(
                         <Grid item xs={12} className="containerBtnSubmit">
                        <div className={"thankYou"}>{getLocale("Thank you!")}</div>
                        </Grid>
                         )} 
                           { error &&(
                         <Grid item xs={12} className="containerBtnSubmit">
                        <div className={"errorOccurred"}>{getLocale("errorOccurred")}</div>
                        </Grid>
                            )}
                        </Grid>
                    </form>
                    )}
                    />
                </div>
            </Popup>

        </div >
    );
}

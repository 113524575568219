import React, { useRef } from "react";
import styles from "./home.module.scss";
import Search from "./SubComponents/search";
// import Select from '../select/select'
import Swiper from "../General/swiper/swiper";
import slider1 from "../../img/slider1.jpg";
import slider2 from "../../img/slider2.jpg";
// import slider3 from "../../img/slider3.jpg";
import Offers from "./SubComponents/offers";
import Packages from "./SubComponents/packages";
import Save from "./SubComponents/save";
import SocialSection from "../General/social-section/social-section";
// import OurAttractions from '../General/Our-Attractions/Our-Attractions'
import Video from "./SubComponents/video";
// import PopUpSkiClupProgram from "./SubComponents/popUpSkiClupProgram/popUpSkiClupProgram";
function Home() {
	const mySwiper = useRef();
	const settings = {
		arrows: false,
		search: <Search />,
		slidesPerView: 1,
		bullets: true,
		loop: true,
		spaceBetween: 5,
	};
	return (
		<div className={`${styles.home} home`}>
			<div className="landing" id="swiperHome">
				<Swiper settings={settings} mySwiper={mySwiper}>
					<div className="swiper-slide">
						<img src={slider1} alt="sliderImg" />
					</div>
					<div className="swiper-slide">
						<img src={slider2} alt="sliderImg" />
					</div>
					{/* <div className="swiper-slide">
						<img src={slider3} alt="sliderImg" />
					</div> */}
				</Swiper>
			</div>
		{/* <div className="popUpSkiClupProgram">	<PopUpSkiClupProgram/></div> */}
			<Offers />
			<Save />
			<Packages />
			<Video />
			
			{/* <Select
                name="test"
                options={[{ value: "test", label: "test" }, { value: "test2", label: "test2" }, { value: "test3", label: "test3" }]}
                placeholder="Select an option"
            /> */}
			<SocialSection />
			{/* <OurAttractions/> */}
		</div>
	);
}

export default Home;

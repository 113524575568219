import React, {useEffect} from "react";
import styles from "../home.module.scss";
import { Container, Grid } from "@material-ui/core";
// import goggles from "../../../img/goggles.svg";
// import jacket from "../../../img/jacket.svg";
// import outline from "../../../img/outline.svg";
// import Popup from "../../General/popup/popup";
// import packagesImg1 from "../../../img/snowgo.jpg";
// import packagesImg2 from "../../../img/family.jpg";
// import packagesImg2 from '../../../img/northpole.jpg'
// import packagesImg3 from '../../../img/pengfriendly.jpg'
// import packagesImg4 from '../../../img/skypengparty.jpg'
// import SwiperSlider from "../../General/swiper/swiper";
import { useSelector } from "react-redux";
import useLocale from "../../../lang/useLocale";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
export default function Save() {
    useEffect(() => {
        AOS.init({
            duration : 1000,
            once: true
        });
    }, []);
    const lang = useSelector((state) => state.lang);
    const { getLocale } = useLocale();
    // const mySwiper = useRef();
    // const settings = {
    // 	arrows: true,
    // 	// loop: true,
    // 	spaceBetween: 10,
    // 	autoplay: {
    // 		delay: 5000,
    // 	},
    // 	autoplayDisableOnInteraction: true,
    // 	slidesPerView: 1,
    // 	bullets: false,
    // };
    return (
        <div role="img" aria-label="سكي ايجيبت" className={`${styles.save} save`}>
            {/* <Container maxWidth="lg" className={styles.banner}> */}
                {/* <h2 className={styles.title}>{getLocale("allyouneed")}</h2> */}
                {/* <Grid container className={styles.itemWrap} justify="center"> */}
                    {/* <Grid item xs={6} sm={4} className={styles.item}>
                        <img src={goggles} alt="x" className={styles.goggles} />
                        <h2>{getLocale("Equipment")}</h2>
                    </Grid>
                    <Grid item xs={6} sm={4} className={styles.item}>
                        <img src={jacket} alt="x" />
                        <h2>{getLocale("Clothes")}</h2>
                    </Grid> */}
                    {/* <Grid item xs={6} sm={3} className={styles.item}>
                        <img src={watch} alt='x'/>
                        <h2>Accessories</h2>
                    </Grid> */}
                    {/* <Grid item xs={6} sm={4} className={styles.item}>
                        <img src={outline} alt="x" />
                        <h2>{getLocale("Instructions")}</h2>
                    </Grid> */}
                {/* </Grid> */}
            {/* </Container> */}
            <div data-aos="fade-up">
            <Container maxWidth="lg" className={styles.text}>
                <Grid container  alignItems="center" direction={lang === "en" ? "row" : "row-reverse"}>
                    <Grid item  style={{textAlign:`${lang === "en" ? "left" : "right"}`}} xs={12}>
                        <h6>{getLocale("skiegypt")}</h6>
                        <h5>{getLocale("exclusiveoffers")}</h5>
                        <h5>{getLocale("ongroups")}</h5>
                        <button className={`saveUpPopup ${styles.button}`}>
                                    <Link to={"/" + lang + "/offers"} rel='canonical'>{getLocale("Explore more")}</Link>
                                </button>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container className={styles.offersContainer} justify={lang === "ar" ? "flex-start" : "flex-start"}>
                            <Grid item md={3} xs={6}>
                          
                                {/* <Popup popupTriggerTitle= {lang === 'en' ? 'VOTE NOW' : 'صوت الان'} popupTriggerClass='saveUpPopup'>
                                    <div id='offersPopup'>
                                        <SwiperSlider settings={settings} mySwiper={ mySwiper }>
                                            <div className={`${styles.packageSlide} swiper-slide`}>
                                                <Grid container className='containerGridAll'>
                                                    <Grid container item xs={12} md={6} className='containerImgRight'>
                                                        <img src={packagesImg1} alt='img'/>
                                                    </Grid>
                                                    <Grid container item xs={12} md={6} className='containerData' justify='flex-start' alignItems='flex-start'>
                                                        <Grid item xs={12} className='Header'>
                                                            <p>{getLocale('Package 1')}</p>
                                                        </Grid>
                                                        <Grid item xs={12} className='description'>
                                                            <p>{getLocale('Package 1 exert')}</p>
                                                            <ul>
                                                                <li>{getLocale('Package 1 list 1')}</li>
                                                                <li>{getLocale('Package 1 list 2')}</li>
                                                                <li>{getLocale('Package 1 list 3')}</li>
                                                                <li>{getLocale('Package 1 list 4')}</li>
                                                            </ul>
                                                        </Grid>
                                                        <Grid container item  xs={12} className='PackagePerPerson'>
                                                            <Grid item className='Header'>
                                                                <p>
                                                                    {getLocale('Total fee')}:
                                                                </p>
                                                            </Grid>
                                                            <Grid container item className='description' justify='flex-start' alignItems='center'>
                                                                <Grid item xs={12} md={4}>
                                                                    <p>800 
                                                                        <span> / {getLocale('LE')} </span>
                                                                    </p>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container justify='space-around' item xs={12} >
                                                                <Grid item xs={6} md={6}>
                                                                    <a href='/' target='_blank' className='bookBtn'> 
                                                                        {getLocale('BOOK NOW')}
                                                                    </a>
                                                                </Grid>
                                                                <Grid item xs={6} md={6}>
                                                                    <a href="tel:16002" rel="noopener noreferrer" className='call'> 
                                                                        {getLocale("CALL US")}
                                                                    </a>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <div className={`${styles.packageSlide} swiper-slide`}>
                                                <Grid container className='containerGridAll'>
                                                    <Grid container item xs={12} md={6} className='containerImgRight'>
                                                        <img src={packagesImg2} alt='img'/>
                                                    </Grid>
                                                    <Grid container item xs={12} md={6} className='containerData' justify='flex-start' alignItems='flex-start'>
                                                        <Grid item xs={12} className='Header'>
                                                            <p>{getLocale('Package 2')}</p>
                                                        </Grid>
                                                        <Grid item xs={12} className='description'>
                                                            <p>{getLocale('Package 2 exert')}</p>
                                                            <ul>
                                                                <li>{getLocale('Package 2 list 1')}</li>
                                                                <li>{getLocale('Package 2 list 2')}</li>
                                                                <li>{getLocale('Package 2 list 3')}</li>
                                                                <li>{getLocale('Package 2 list 4')}</li>
                                                            </ul>
                                                        </Grid>
                                                        <Grid container item  xs={12} className='PackagePerPerson'>
                                                            <Grid item className='Header'>
                                                                <p>
                                                                    {getLocale('Total fee')}: 
                                                                </p>
                                                            </Grid>
                                                            <Grid container item className='description' justify='flex-start' alignItems='center'>
                                                                <Grid item xs={12} md={4}>
                                                                    <p>925 
                                                                        <span> / {getLocale('LE')} </span>
                                                                    </p>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container justify='space-around' item xs={12} >
                                                                <Grid item xs={6} md={6}>
                                                                    <a href='/' target='_blank' className='bookBtn'> 
                                                                        {getLocale('BOOK NOW')}
                                                                    </a>
                                                                </Grid>
                                                                <Grid item xs={6} md={6}>
                                                                    <a href="tel:16002" rel="noopener noreferrer" className='call'> 
                                                                        {getLocale("CALL US")}
                                                                    </a>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </div>  
                                        </SwiperSlider>
                                    </div>
                                
                                </Popup>
                             */}
                              
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            </div>
        </div>
    );
}

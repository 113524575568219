import React, {
  Fragment,
  useRef,
  useLayoutEffect,
  useState,
  useEffect,
} from "react";
import styles from "./socialSection.module.scss";
import { Container, Grid } from "@material-ui/core";
import useLocale from "../../../lang/useLocale";
import Swiper from "../swiper/swiper";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { fbToken } from "../../../utils/fbToken";
import { handlefbFetchData } from "../../../redux/actions/fb";
import { handleigFetchData } from "../../../redux/actions/ig";
import Tripadvisor from "../social-section/tripadvisor/tripadvisor";
import AOS from "aos";
import "aos/dist/aos.css";

function SocialSection() {
  useEffect(() => {
    AOS.init({
      // duration : 5000
      once: true,
    });
  }, []);
  const dispatch = useDispatch();
  const { getLocale } = useLocale();
  const mySwiper = useRef();
  const fb = useSelector((state) => state.fb);
  const ig = useSelector((state) => state.ig);
  const lang = useSelector((state) => state.lang);

  const [index, setIndex] = useState(null);
  useLayoutEffect(() => {
    if (fb) {
      if (index) {
        Axios.get(fb.next).then((res) => {
          dispatch(handlefbFetchData(res?.data));
          setIndex(false);
        });
      }
    }
    // eslint-disable-next-line
  }, [fb, index]);
  useLayoutEffect(() => {
    if (ig) {
      if (index) {
        Axios.get(ig.next).then((res) => {
          dispatch(handleigFetchData(res?.data));
          setIndex(false);
        });
      }
    }
    // eslint-disable-next-line
  }, [ig, index]);
  const handleChange = () => {
    if (mySwiper.current.isEnd) {
      setIndex(true);
    }
  };
  const [autoplay, setautoplay] = useState({
    delay: 5000,
  });
  useEffect(() => {
    if (lang) {
      mySwiper.current.autoplay.stop();
      // console.log(mySwiper.current.autoplay);
      setautoplay({
        delay: 5000,
      });
    }
  }, [lang]);
  const settings = {
    loop: false,
    spaceBetween: 0,
    breakpoints: {
      768: {
        slidesPerView: 4,
      },
    },
    autoplay: autoplay,
    autoplayDisableOnInteraction: true,
    on: {
      slideChange: function () {
        handleChange();
      },
    },
  };
  const [fanCount, setFanCount] = useState(null);
  const [social, setSocial] = useState("all");
  const allRef = useRef(null);
  const fbRef = useRef(null);
  const igRef = useRef(null);
  const [igCount, setigCount] = useState(null);
  function handleSocial(e) {
    setSocial(e.currentTarget.dataset.value);
    e.currentTarget.className = "activeIcon";
    if (e.currentTarget.dataset.value === "all") {
      fbRef.current.className = "";
      igRef.current.className = "";
    } else if (e.currentTarget.dataset.value === "fb") {
      allRef.current.className = "";
      igRef.current.className = "";
    } else if (e.currentTarget.dataset.value === "ig") {
      allRef.current.className = "";
      fbRef.current.className = "";
    }
  }

  useLayoutEffect(() => {
    Axios.get(
      `https://graph.facebook.com/skiegypt/?fields=fan_count&access_token=${fbToken}`
    ).then((res) => {
      if (res?.data?.fan_count >= 1000000000) {
        setFanCount(
          (res.data.fan_count / 1000000000).toFixed(1).replace(/\.0$/, "") + "G"
        );
      }
      if (res?.data?.fan_count >= 1000000) {
        setFanCount(
          (res.data.fan_count / 1000000).toFixed(1).replace(/\.0$/, "") + "M"
        );
      }
      if (res?.data?.fan_count >= 1000) {
        setFanCount(
          (res.data.fan_count / 1000).toFixed(1).replace(/\.0$/, "") + "K"
        );
      }
    });
  }, []);
  useLayoutEffect(() => {
    let count;
    if (window?._igCount) {
      count = window?._igCount?.data[0]?.followed_by_count;
    }
    if (count >= 1000000000) {
      setigCount((count / 1000000000).toFixed(1).replace(/\.0$/, "") + "G");
    }
    if (count >= 1000000) {
      setigCount((count / 1000000).toFixed(1).replace(/\.0$/, "") + "M");
    }
    if (count >= 1000) {
      setigCount((count / 1000).toFixed(1).replace(/\.0$/, "") + "K");
    }
    // eslint-disable-next-line
  }, [window._igCount]);

  return (
    <Fragment>
      <Tripadvisor />

      <Container maxWidth="lg" className={`${styles.YourExperience}`}>
        <Grid
          data-aos="fade-up"
          container
          justify="center"
          alignItems="center"
          direction={lang === "en" ? "row" : "row-reverse"}
        >
          {/* <div data-aos="fade-up"> */}
          <Grid item xs={12} md={8} className={`${styles.TitelYourExperience}`}>
            <p>
              {getLocale("CONNECT WITH SKIEGYPT & SHARE")}
              <span> {getLocale("YOUR EXPERIENCE")}</span>
            </p>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            className={`${styles.containersocialIcon}`}
            justify="flex-end"
            alignItems="flex-start"
            direction={lang === "en" ? "row" : "row-reverse"}
          >
            <Grid
              container
              item
              xs={4}
              md={4}
              className={`${styles.socialIcon}`}
            >
              <Grid container item>
                <Grid
                  item
                  xs={12}
                  className={`${styles.facebook} ${
                    lang === "en" ? styles.Line : null
                  }`}
                >
                  <div
                    onClick={(e) => handleSocial(e)}
                    data-value="all"
                    ref={allRef}
                    className={`${styles.globe}`}
                  >
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      icon={faGlobe}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} className={`${styles.label}`}>
                  <span>All</span>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={4}
              md={4}
              className={`${styles.socialIcon}`}
            >
              <Grid container item>
                <Grid
                  item
                  xs={12}
                  className={`${styles.facebook} ${styles.Line}`}
                >
                  <div
                    onClick={(e) => handleSocial(e)}
                    data-value="ig"
                    ref={igRef}
                  >
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      icon={faInstagram}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} className={`${styles.label}`}>
                  <span>
                    {igCount}
                    <br />
                    Followers
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={4}
              md={4}
              className={`${styles.socialIcon}`}
            >
              <Grid container item>
                <Grid item xs={12} className={`${styles.facebook}`}>
                  <div
                    onClick={(e) => handleSocial(e)}
                    data-value="fb"
                    ref={fbRef}
                  >
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      icon={faFacebookF}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} className={`${styles.label}`}>
                  <span>
                    {fanCount}
                    <br />
                    Likers
                  </span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* </div> */}
        </Grid>
      </Container>

      <div className={`YourExperience ${styles.fbSlides}`}>
        {fb && ig ? (
          fb.data && ig.data ? (
            social ? (
              <Swiper
                key={`${lang}-swiper`}
                settings={settings}
                style={{ height: "168px" }}
                mySwiper={mySwiper}
              >
                {fb.data && (social === "all" || social === "fb")
                  ? fb.data.map((post) => {
                      if (post.full_picture) {
                        return (
                          <div
                            className={`${styles.slide} swiper-slide`}
                            key={post.id}
                          >
                            <div>
                              <div className={styles.cardImgWrap}>
                                <img
                                  src={post.full_picture}
                                  alt="img"
                                  className="socialImg"
                                />
                                <a
                                  href={post.permalink_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <span
                                    className={`${styles.containerOverlaye}`}
                                  >
                                    <FontAwesomeIcon icon={faFacebookF} />
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })
                  : null}
                {ig.data && (social === "all" || social === "ig")
                  ? ig.data.map((post, index) => {
                      if (post.thumbnail_url) {
                        return (
                          <div
                            className={`${styles.slide} swiper-slide`}
                            key={index}
                          >
                            <div>
                              <div className={styles.cardImgWrap}>
                                <img
                                  src={post.thumbnail_url}
                                  alt="img"
                                  className="socialImg"
                                />
                                <a
                                  href={post.permalink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <span
                                    className={`${styles.containerOverlaye}`}
                                  >
                                    <FontAwesomeIcon icon={faInstagram} />
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })
                  : null}
              </Swiper>
            ) : null
          ) : null
        ) : null}
      </div>
    </Fragment>
  );
}

export default React.memo(SocialSection);

import React from 'react';
import styles from './corporateBenefits.module.scss'
import { Container, Grid } from "@material-ui/core";
import ContactUs from '../contactUs/contactUs';
import { useSelector } from "react-redux";



export default function CorporateBenefits(props) {
    const browserLang = useSelector((state) => state.lang);

    return (
        <div className={styles.corporateBenefitsContainer}>
            <Container>
                <Grid container spacing={1} justify="space-between" style={{ textAlign: browserLang === "ar" ? "right" : "left" }}>
                    <Grid item md={3} xs={6}>
                        <img src={props.imgOne} alt="" />
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <img src={props.imgTwo} alt="" />
                    </Grid>
                    <Grid item md={6}>
                        <h3>{props.title}</h3>
                        <p>{props.paragraph}</p>
                        <ContactUs subject={props.title} />
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

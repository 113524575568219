import React, { useEffect } from "react";
import styles from "./personalitytest.module.scss";
import { Container, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
export default function Personalitytest() {
    useEffect(() => {
        AOS.init({
            duration : 1000,
            once: true
        });
    }, []);
    return (
            <div role="img" className={`${styles.container}`}>
                <div data-aos="fade-up">
                <Container className={styles.text}>
                    <Grid container alignItems="center">
                        <Grid item xs={12}>
                            <h6>check out our</h6>
                            <h5>personality test</h5>
                            <p>Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec admodum perfecto mnesarchum, vim ea mazim fierent detracto. Ea quis iuvaret expetendis his, te elit voluptua dignissim per,</p>
                            <button className={`${styles.button}`}>
                                <Link >take test</Link>
                            </button>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container className={styles.offersContainer}>
                                <Grid item md={3} xs={6}>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div>
    );
}

import React, { useRef, useLayoutEffect } from "react";
import styles from "../penguins.module.scss";
import Slider from "../../General/swiper/swiper";
import patrick from "../../../img/patrick.jpg";
import zeina from "../../../img/zeina.jpg";
import jj from "../../../img/jj.jpg";
import nando from "../../../img/nando.jpg";
import nelly from "../../../img/nelly.jpg";
import nazly from "../../../img/nazly.jpg";
import sushi from "../../../img/sushi.jpg";
import shira from "../../../img/shira.jpg";
import cairo from "../../../img/cairo.jpg";
import sultanbg from "../../../img/sultanbg.png";
import { Grid, Container } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import type from "../../../img/type.svg";
import gender from "../../../img/gender.svg";
// import twitterSultan from "../../../img/twitterSultan.svg";
import instSultan from "../../../img/instSultan.svg";
import useLocale from "../../../lang/useLocale";
import { useSelector } from "react-redux";

const MeetPenguins = (props) => {
  const lang = useSelector((state) => state.lang);
  const { getLocale } = useLocale();
  const mySwiper = useRef();
  const settings = {
    arrows: false,
    bullets: true,
    // loop: true,
    spaceBetween: 20,
    breakpoints: {
      640: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      1025: {
        slidesPerView: 4,
      },
    },
  };
  const meetPenguinsRef = useRef(null);

  useLayoutEffect(() => {
    if (
      props.location?.state?.moveTo === "meetPenguins" &&
      meetPenguinsRef.current
    ) {
      setTimeout(() => {
        meetPenguinsRef.current.scrollIntoView();
      }, 1000);
    }
  }, [meetPenguinsRef, props.location]);

  return (
    <div className={styles.penguinWrap}>
      <Container>
        <div
          className={`${styles.ticketsPenguins} penguins`}
          ref={meetPenguinsRef}
          id="meet"
        >
          <div className={styles.meet}>
            <h4>{getLocale("meetpenguins")}</h4>
          </div>
          <Grid
            container
            justify="center"
            alignItems="center"
            className={styles.sultanWrapper}
          >
            <Grid item sm={12} md={12}>
              {/* <img src={sultanStar} alt='penguin'/> */}
              <Grid
                container
                justify="space-between"
                alignItems="center"
                direction={lang === "en" ? "row" : "row-reverse"}
              >
                <Grid item xs={12} md={6}>
                  <h5 className={styles.sultan}>
                    {getLocale("'SULTAN' OUR STAR")}
                  </h5>
                  <p className={styles.sultanexert}>
                    {getLocale("sultanexert")}
                  </p>
                  <div className={styles.follow}>
                    {getLocale("follow Sultan on")}
                    <span>
                      {/* <a href='https://mobile.twitter.com/sultanelpenguin' target='_blank' alt='social' rel="noopener noreferrer">
                                                <img src={twitterSultan} alt='img'/>
                                            </a> */}
                      <a
                        href="https://www.instagram.com/sultanthepenguin/?igshid=srmk3hqj4jim"
                        target="_self"
                        alt="social"
                        rel="noopener noreferrer"
                      >
                        <img src={instSultan} alt="img" />
                      </a>
                    </span>
                  </div>
                </Grid>
                <Grid
                  container
                  item
                  justify={lang === "en" ? "flex-end" : "flex-start"}
                  xs={12}
                  md={6}
                  alignItems="center"
                  className={styles.sultanIconsWrap}
                >
                  <Grid item xs={12}>
                    <img src={sultanbg} alt="img" />
                  </Grid>
                  <Grid item xs={12} container direction="row" justify="center">
                    <Grid item xs={3} className={styles.sultanPeng}>
                      <img src={type} alt="img" /> <span>Gentoo</span>
                    </Grid>
                    <Grid item xs={3} className={styles.sultanPeng}>
                      <img src={gender} alt="img" />{" "}
                      <span>{getLocale("Male")}</span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div id="swiperPenguin" className={styles.meetSection}>
            <Slider
              key={`${lang}-swiper`}
              settings={settings}
              mySwiper={mySwiper}
            >
              <div className={`swiper-slide ${styles.penguinSlide}`}>
                <img src={cairo} alt="ski penguins" />
                <h5>CAIRO</h5>
                <Grid
                  container
                  item
                  justify="space-between"
                  xs={12}
                  alignItems="center"
                >
                  <Grid item xs={6} className={styles.sliderItem}>
                    <img src={type} alt="img" /> <span>Gentoo</span>
                  </Grid>
                  <Grid item xs={6} className={styles.sliderItem}>
                    <img src={gender} alt="img" />{" "}
                    <span>{getLocale("Female")}</span>
                  </Grid>
                </Grid>
              </div>
              <div className={`swiper-slide ${styles.penguinSlide}`}>
                <img src={patrick} alt="ski egypt penguins" />
                <h5>PATRICK</h5>
                <Grid
                  container
                  item
                  justify="space-between"
                  xs={12}
                  alignItems="center"
                >
                  <Grid item xs={6} className={styles.sliderItem}>
                    <img src={type} alt="img" /> <span>Gentoo</span>
                  </Grid>
                  <Grid item xs={6} className={styles.sliderItem}>
                    <img src={gender} alt="img" />{" "}
                    <span>{getLocale("Male")}</span>
                  </Grid>
                </Grid>
              </div>
              <div className={`swiper-slide ${styles.penguinSlide}`}>
                <img src={zeina} alt="بطريق مول مصر" />
                <h5>ZEINA</h5>
                <Grid
                  container
                  item
                  justify="space-between"
                  xs={12}
                  alignItems="center"
                >
                  <Grid item xs={6} className={styles.sliderItem}>
                    <img src={type} alt="img" /> <span>Chinstrap</span>
                  </Grid>
                  <Grid item xs={6} className={styles.sliderItem}>
                    <img src={gender} alt="img" />{" "}
                    <span>{getLocale("Female")}</span>
                  </Grid>
                </Grid>
              </div>
              <div className={`swiper-slide ${styles.penguinSlide}`}>
                <img src={shira} alt="بطريق سكي مصر" />
                <h5>SHIRA</h5>
                <Grid
                  container
                  item
                  justify="space-between"
                  xs={12}
                  alignItems="center"
                >
                  <Grid item xs={6} className={styles.sliderItem}>
                    <img src={type} alt="img" /> <span>Chinstrap</span>
                  </Grid>
                  <Grid item xs={6} className={styles.sliderItem}>
                    <img src={gender} alt="img" />{" "}
                    <span>{getLocale("Female")}</span>
                  </Grid>
                </Grid>
              </div>
              <div className={`swiper-slide ${styles.penguinSlide}`}>
                <img src={nando} alt="بطاريق سكي" />
                <h5>NANDO</h5>
                <Grid
                  container
                  item
                  justify="space-between"
                  xs={12}
                  alignItems="center"
                >
                  <Grid item xs={6} className={styles.sliderItem}>
                    <img src={type} alt="img" /> <span>Chinstrap</span>
                  </Grid>
                  <Grid item xs={6} className={styles.sliderItem}>
                    <img src={gender} alt="img" />{" "}
                    <span>{getLocale("Male")}</span>
                  </Grid>
                </Grid>
              </div>
              <div className={`swiper-slide ${styles.penguinSlide}`}>
                <img src={jj} alt="بطريق مول مصر" />
                <h5>JJ</h5>
                <Grid
                  container
                  item
                  justify="space-between"
                  xs={12}
                  alignItems="center"
                >
                  <Grid item xs={6} className={styles.sliderItem}>
                    <img src={type} alt="img" /> <span>Chinstrap</span>
                  </Grid>
                  <Grid item xs={6} className={styles.sliderItem}>
                    <img src={gender} alt="img" />{" "}
                    <span>{getLocale("Male")}</span>
                  </Grid>
                </Grid>
              </div>
              <div className={`swiper-slide ${styles.penguinSlide}`}>
                <img src={nelly} alt="بطريق سكي" />
                <h5>NELLY</h5>
                <Grid
                  container
                  item
                  justify="space-between"
                  xs={12}
                  alignItems="center"
                >
                  <Grid item xs={6} className={styles.sliderItem}>
                    <img src={type} alt="img" /> <span>Gentoo</span>
                  </Grid>
                  <Grid item xs={6} className={styles.sliderItem}>
                    <img src={gender} alt="img" />{" "}
                    <span>{getLocale("Female")}</span>
                  </Grid>
                </Grid>
              </div>
              <div className={`swiper-slide ${styles.penguinSlide}`}>
                <img src={nazly} alt="penguin ski" />
                <h5>NAZLY</h5>
                <Grid
                  container
                  item
                  justify="space-between"
                  xs={12}
                  alignItems="center"
                >
                  <Grid item xs={6} className={styles.sliderItem}>
                    <img src={type} alt="img" /> <span>Gentoo</span>
                  </Grid>
                  <Grid item xs={6} className={styles.sliderItem}>
                    <img src={gender} alt="img" />{" "}
                    <span>{getLocale("Female")}</span>
                  </Grid>
                </Grid>
              </div>
              <div className={`swiper-slide ${styles.penguinSlide}`}>
                <img src={sushi} alt="البطريق في مول مصر" />
                <h5>SUSHI</h5>
                <Grid
                  container
                  item
                  justify="space-between"
                  xs={12}
                  alignItems="center"
                >
                  <Grid item xs={6} className={styles.sliderItem}>
                    <img src={type} alt="img" /> <span>Gentoo</span>
                  </Grid>
                  <Grid item xs={6} className={styles.sliderItem}>
                    <img src={gender} alt="img" />{" "}
                    <span>{getLocale("Male")}</span>
                  </Grid>
                </Grid>
              </div>
            </Slider>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default withRouter(MeetPenguins);
